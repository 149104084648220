module.exports = {
  // Country Comments
  // Titles
  countryComments: 'Country Comments',
  forest: 'Forest',
  forestArea: 'Forest area',
  forestAreaByForestType: 'Forest area by forest type',
  growingStock: 'Growing stock',
  growingStockByForestType: 'Growing stock by forest type',
  growingStockComposition: 'Growing stock composition',
  ageClassDistribution: 'Age class distribution (volume of even-aged stands) in forest available for wood supply',
  carbonStockInHarvestedWoodProductsHWP: 'Carbon stock in harvested wood products (HWP)',
  annualForestExpansionAndRegeneration: 'Annual forest expansion and regeneration',
  other: 'Other',
  naturalnessBySubclass: 'Naturalness by subclass',
  introducedTreeSpecies: 'Introduced tree species',
  invasiveTreeSpecies: 'Invasive tree species',
  totalFixedCapitalFormationInForestsAndForestry: 'Total fixed capital formation in forests and forestry',
  totalFixedCapitalTransfersInForestsAndForestry: 'Total fixed capital transfers in forests and forestry',
  EmploymentByEducationAndJobCharacteristics: 'Employment by education and job characteristics',
  intensityOfUse: 'Intensity of use',
  recreationFacilities: 'Recreation facilities',
  otherRecreationFacilities: 'Other recreation facilities - pilot reporting (2020)',
  // Rows and columns
  theYearAndDataReported: 'The year and data reported for 2025',
  howDidYouGenerateValues: 'How did you generate values for 2025',
  theRecentAvailableYear: 'The year of recent available data',
  extrapolation: 'Extrapolation',
  assessmentBasedOnEvidence:
    'Assessment based on evidence (e.g. forecast, outlooks, national afforestation programmes, forest policy targets)',
  approachToReportingOnTheCategoriesOfTheTable11A: 'Approach to reporting on the categories of the Table 1.1a',
  category: 'Category',
  areaEstimate: 'Area estimate (1000 ha)',
  comments: 'Comments',
  commentsRelateToDataDefinitions: 'Comments related to data, definitions, etc.',
  commentsOnTrendS: 'Comments on trend(s)',
  commentsOnTrend: 'Comments on trend(s)',
  valuesAccordingFaoDefinitionGrowingStock:
    'Please indicate if reported values are according to the FAO definition of growing stock',
  PleaseSpecifyRelevantThresholds: 'Please specify relevant thresholds',
  minimumDiameter: 'Minimum diameter',
  minimumTopDiameterUsed: 'Minimum top diameter used',
  minimumBranchDiameterUsed: 'Minimum branch diameter used',
  isVolumeAboveGroundOrAboveStump: 'Is volume above ground or above stump?',
  generalComments: 'General comments',
  growingStockOnForestArea: 'Growing stock on forest area',
  ofWhichAvailableForWoodSupply: '... of which available for wood supply',
  owlArea: 'OWL area',
  growingStockOnOwl: 'Growing stock on OWL',
  growingStockOnFowls: 'Growing stock on FOWLS',
  forestTypes: 'Forest types',
  approachToDelineationBetweenEvenAgedAndUnevenAgedStands:
    'Approach to delineation between even-aged and uneven-aged stands',
  howIdYouDistinguishBetweenEvenAgedAndUnevenAgedStands:
    'How did you distinguish between even-aged and uneven-aged stands?',
  commentsToInterpretationOfDevelopmentPhases: 'Comments to interpretation of development phases',
  howDidYouInterpretedAndDefineNationally: 'How did you interpreted and define nationally',
  regenerationPhase: 'Regeneration phase',
  intermediatePhase: 'Intermediate phase',
  maturePhase: 'Mature phase',
  unspecified: 'Unspecified',
  EvenAgedStands: 'Even-aged stands',
  forestEvenAgedStands: 'Forest: Even-aged stands',
  totalAreaOfUnevenAgedForest: 'Total area of uneven-aged forest',
  totalVolumeOfUnevenAgedForests: 'Total volume of uneven-aged forests',
  diameterDistributionForUnevenAgedForests: 'Diameter distribution for uneven-aged forests',
  diameterDistributionForUnevenAgedForestsAvailableForWoodSupply:
    'Diameter distribution for uneven-aged forests available for wood supply',
  theYearAndDataReportedFor2025: 'The year and data reported for 2025',
  howDidYouGenerateValuesFor2025: 'How did you generate values for 2025',
  approachToReportingOnGrowingStock: 'Approach to reporting on growing stock',
  ifNotPleaseSpecifyRelevantThresholds: 'If not, please specify relevant thresholds',
  commentsRelatedToDataDefinitions: 'Comments related to data, definitions, etc.',
  commentsOnTrends: 'Comments on trends',
  approachToDetermineDiameterStructure: 'Approach to determine diameter structure',
  describeHowYouDetermineDiameterStructure: 'Describe how you determine diameter structure',
  commentsToInterpretationOfDevelopmentPhasesForGrowingStock:
    'Comments to interpretation of development phases for growing stock',
  commentsRelatedToDataDefinitionsConversionFactorsUsed:
    'Comments related to data, definitions, conversion factors used',
  groundLivingBiomass: 'Ground living biomass',
  carbonStockInBelowGroundLivingBiomass: 'Carbon stock in below ground living biomass',
  carbonStockInDeadwood: 'Carbon stock in deadwood',
  carbonStockInLitter: 'Carbon stock in litter',
  carbonStockInSoil: 'Carbon stock in soil',
  biomassCarbonConversionFactorUsed: 'Biomass/carbon conversion factor used',
  criteriaAppliedToReportingDamage: 'Criteria applied to reporting damage',
  minimumSizeOfDamagedFowlReported: 'Minimum size of damaged fowl reported',
  otherCriteriaAndMinimumThresholdsUsedToDetermineAreaAsDamaged:
    'Other criteria and minimum thresholds used to determine area as damaged',
  criteriaUsedToDetermineWhichAgentsWerePrimarilyDamaging:
    'Criteria used to determine which agents were primarily damaging',
  areDamageInProtectedForestsIncludedInTheReportedFigures:
    'Are damage in protected forests included in the reported figures?',
  totalAreaWithDamage: 'Total area with damage',
  primarilyDamagedByInsectsAndDisease: 'Primarily damaged by insects and disease',
  primarilyDamagedByWildlifeAndGrazing: 'Primarily damaged by wildlife and grazing',
  damagePrimarilyHumanInducedForestOperations: 'Damage primarily human induced forest operations',
  humanInducedDamagesReportedUnderOther: 'Human induced damages reported under other',
  primarilyDamagedByAbioticAgents: 'Primarily damaged by abiotic agents',
  primarilyDamagedByFire: 'Primarily damaged by fire',
  unspecifiedMixedDamage: 'Unspecified mixed damage',
  grossAnnualIncrement: 'Gross annual increment',
  naturalLosses: 'Natural losses',
  netAnnualIncrement: 'Net annual increment',
  totalFellings: 'Total fellings',
  fellingsOfNaturalLosses: 'Fellings of natural losses',
  approachApplieToReportingOnRemovals: 'Approach applied to reporting on removals',
  approachUsedToDetermineMarketedWood: 'Approach used to determine marketed wood',
  approachUsedToDetermineValueOfWood: 'Approach used to determine value of wood',
  industrialRoundwoodRemovalsVolume: 'Industrial roundwood removals volume',
  industrialRoundwoodRemovalsValue: 'Industrial roundwood removals value',
  woodfuelRemovalsVolume: 'Woodfuel removals volume',
  woodfuelRemovalsValue: 'Woodfuel removals value',
  product: 'Product',
  carbonStockInAboveGroundLivingBiomass: 'Carbon stock in above-ground living biomass',
  commentsOnTren: 'Comments on trend(s)',
  first: '1st',
  second: '2nd',
  third: '3rd',
  fourth: '4th',
  fifth: '5th',
  sixth: '6th',
  seventh: '7th',
  eighth: '8th',
  ninth: '9th',
  tenth: '10th',
  countryComments1StPart: 'Country comments (1st part)',
  countryComments2NdPart: 'Country comments (2nd part)',
  countryComments3Rd: 'Country comments (3rd part)',
  describeMethodUsedToDetermineFellings: 'Describe method used to determine fellings',
  ifApplicableBarkConversionFactorFromOverBarkToUnderBark:
    'If applicable, bark conversion factor from over bark to under bark',
  approachUsedToDetermineMarketed: 'Approach used to determine marketed',
  approachUsedToDetermineValue: 'Approach used to determine value',
  approachToDetermineFellings: 'Approach to determine fellings',
  countryComments4Th: 'Country comments (4th part)',
  countryComments5Th: 'Country comments (5th part)',
  countryComments6Th: 'Country comments (6th part)',
  countryComments7Th: 'Country comments (7th part)',
  countryComments8Th: 'Country comments (8th part)',
  countryComments9Th: 'Country comments (9th part)',
  countryComments10Th: 'Country comments (10th part)',
  allOtherPlantProducts: 'All other plant products',
  allOtherAnimalProducts: 'All other animal products',
  referenceAreaIfDifferentFromTotalFowlEGOwnershipClassOr1000Ha:
    'Reference area (if different from total fowl, e.g. ownership class or 1000 ha)',
  approachToReportOnTreeSpeciesComposition: 'Approach to report on tree species composition',
  minimumSizeOfTreesToBeIncluded: 'Minimum size of trees to be included',
  doesYourCountryHaveANationalMethodology:
    'Does your country have a national methodology for land degradation assessment?',
  ifYesWhatIsTheNationalDefinition: 'If yes, what is the national definition of "Degraded forest"?',
  ifYesDescribeMonitoringProcessAndResults: 'If yes, describe the monitoring process and results',
  minimumSizeOfDegradedForestReportedHa: 'Minimum size of degraded forest reported (ha)',
  otherCriteriaAndMinimumThresholdsUsedToDetermineForestAsDegraded:
    'Other criteria and minimum thresholds used to determine forest as "degraded"',
  criteriaUsedToDeterminePrimaryTypeOfDegradation: 'Criteria used to determine primary type of degradation',
  doesYourCountryHaveANationalMethodologyForLandDegradationAssessment:
    'Does your country have a national methodology for land degradation assessment?',
  totalAreaOfDegradedLand: 'Total area of degraded land',
  primarilyDegradedBy: 'Primarily degraded by',
  primarilyDegradedByGrazing: 'Primarily degraded by grazing',
  primarilyDegradedByRepeatedFires: 'Primarily degraded by repeated fires',
  primarilyDegradedByAirPollution: 'Primarily degraded by air pollution',
  primarilyDegradedByDesertification: 'Primarily degraded by desertification',
  formerDegradedLandRestoredByAfforestationReforestation:
    'Former degraded land restored by afforestation/reforestation',
  referenceAreaForTheAssessment: 'Reference area for the assessment',
  areaWithNumberOfTreeSpeciesOccurringOnForest: 'Area with number of tree species occurring on forest',
  areaWithNumberOfTreeSpeciesOccurringOnOwl: 'Area with number of tree species occurring on owl',
  areaWithNumberOfTreeSpeciesOccurringOnForestAndOwl: 'Area with number of tree species occurring on forest and owl',
  totalAreaOfForestByExpansionRegenerationType: 'Total area of forest by expansion/regeneration type',
  naturalExpansionAndRegeneration: 'Natural expansion and regeneration',
  afforestationAndRegenerationByPlantingAndOrSeeding: 'Afforestation and regeneration by planting and/or seeding',
  coppice: 'Coppice',
  naturalExpansionOfForestArea: 'Natural expansion of forest area',
  regenerationOfForestArean: 'Regeneration of forest area',
  approachToDelimniateBetweenCategories: 'Approach to delimitate between categories',
  criteriaOrThresholdsUsedToDelimitUndisturbedByManFromSemiNatural:
    'Criteria or theresholds used to delimit undisturbed by man from seminatural',
  criteriaOrThresholdsUsedToDelimitSemiNaturalFromPlantations:
    'Criteria or thresholds used to delimit seminatural from plantations',
  fowlUndisturbedByMan: 'Fowl: undisturbed by man',
  fowlSemiNatural: 'Fowl: semi-natural',
  fowlPlantations: 'Fowl: plantations',
  BarkConversionFactorFromOverBarkToUnderBark: 'Bark conversion factor from "over bark" to "under bark"',
  approachApplieToReportingOnIntroducedInvasiveSpecies: 'Approach applied to reporting on introduced/invasive species',
  referenceAreaForAssessmentSizeOfSamplePlotAverageStand:
    'Reference area for assessment (size of sample plot, average stand)',
  referencePeriodUsedToClassifyAsIntroducedYearsSinceIntroductionYears:
    'Reference period used to classify as introduced (years since introduction, years since first detection)',
  criteriaOrThresholdsUsedToClassifySpeciesAsInvasive: 'Criteria or thresholds used to classify species as invasive',
  general: 'General',
  AreaDominatedByIntroducedTreeSpecies: 'Area dominated by introduced tree species',
  areaDominatedByInvasiveTreeSpecies: 'Area dominated by invasive tree species',
  nonWoodyInvasiveSpecies: 'Non-woody invasive species',
  approachAppliedToReportingOnDeadwood: 'Approach applied to reporting on deadwood',
  pleaseIndicateIfReportedValuesAreAccordingToTheRecommendedMinimumSizes:
    'Please indicate if reported values are according to the recommended minimum sizes',
  approachToReportingOnDeadwood: 'Approach to reporting on deadwood',
  minimumHeightOfStandingDeadwoodReported: 'Minimum height of standing deadwood reported',
  minimumDiameterOfStandingDeadwoodReported: 'Minimum diameter of standing deadwood reported',
  minimumLengthOfLyingDeadwoodReported: 'Minimum length of lying deadwood reported',
  minimumDiameterOfLyingDeadwoodReported: 'Minimum diameter of lying deadwood reported',
  standingDeadwood: 'Standing deadwood',
  lyingDeadwoo: 'Lying deadwood',
  approachAppliedToReportingOnThreatenedForestSpecies: 'Approach applied to reporting on threatened forest species',
  pleaseSpecifyWhichMainTaxaInTheTableAndReportingNotesAreNotAssessedInYourCountry:
    'Please specify which main taxa in the table and reporting notes are not assessed in your country',
  endangeredTrees: 'Endangered trees',
  endangeredBirds: 'Endangered birds',
  endangeredMammals: 'Endangered mammals',
  endangeredOtherVertebrates: 'Endangered other vertebrates',
  endangeredInvertebrates: 'Endangered invertebrates',
  endangeredVascularPlants: 'Endangered vascular plants',
  endangeredCryptogamsAndFungi: 'Endangered cryptogams and fungi',
  pleaseDescribeHowSpeciesWereClassifiedAsForestSpeciesInReportingFromYourCountry:
    'Please describe how species were classified as forest species in reporting from your country',
  forEachClassPleaseProvideAnExplanationIfAndHowYouClassifiedNatura2000AreasInYourReporting:
    'For each class, please provide an explanation if and how you classified Natura 2000 areas in your reporting',
  fowlMcpfeClass11: 'Fowl: MCPFE class 1.1',
  fowlMcpfeClass12: 'Fowl: MCPFE class 1.2',
  fowlMcpfeClass13: 'Fowl: MCPFE class 1.3',
  fowlMcpfeClass2: 'Fowl: MCPFE class 2',
  ForEachClassPleaseProvideAnExplanationHowDidYouDesignateThoseAreasEGLegalBasedDesignationManagementPlansOtherDesignationTypesEGSurveySlopeGradientEtHowDidYouClassifiedNatura2000AreasInYourReporting:
    'For each class, please provide an explanation how did you designate those areas (e.g. legal based designation, management plans, other designation types, e.g. survey, slope gradient, etc.) and how did you classified Natura 2000 areas in your reporting',
  soilWaterAndOtherForestEcosystemFunctions: 'Soil, water and other forest ecosystem functions',
  infrastructureAndManagedNaturalResources: 'Infrastructure and managed natural resources',
  minimumSizeOfForestHoldingReportedHa: 'Minimum size of forest holding reported (ha)',
  areaAndNumberOfHoldingsInPrivateOwnership: 'Area and number of holdings in private ownership',
  areaAndNumberOfHoldingsInPublicOwnership: 'Area and number of holdings in public ownership',
  manufactureOfPaperAndPaperProducts: 'Manufacture of paper and paper products',
  factorIncome: 'Factor income',
  netOperatingSurplus: 'Net operating surplus',
  totalGrossFixedCapitalFormation: 'Total gross fixed capital formation',
  grossFixedCapitalFormationInPlantingOfTreesToProvideRegularIncome:
    'Gross fixed capital formation in planting of trees to provide regular income',
  grossFixedCapitalFormationInEquipmentAndBuildings: 'Gross fixed capital formation in equipment and buildings',
  otherGrossFixedCapitalFormation: 'Other gross fixed capital formation',
  totalFixedCapitalConsumption: 'Total fixed capital consumption',
  fixedCapitalConsumptionInPlantingOfTreesToProvideRegularIncome:
    'Fixed capital consumption in planting of trees to provide regular income',
  fixedCapitalConsumptionInEquipmentAndBuildings: 'Fixed capital consumption in equipment and buildings',
  otherFixedCapitalConsumption: 'Other fixed capital consumption',
  totalCapitalTransfers: 'Total capital transfers',
  scopeOfEmploymentReportedIfNotFromLfs: 'Scope of employment reported (if not from LFS)',
  commentsOnEmploymentWhichIsNotCoveredByTheSourcesUsed:
    'Comments on employment which is not covered by the sources used',
  forestry: 'Forestry',
  manufactureOfWoodAndOfProductsOfWood: 'Manufacture of wood and of products of wood',
  manufactureOfWoodAndArticlesInWood: 'Manufacture of wood and of articles in wood',
  thresholdForReportingNonFatalAccidentsDaysOfAbsence: 'Threshold for reporting non-fatal accidents (days of absence)',
  fatalAccidents: 'Fatal accidents',
  nonFatalAccidents: 'Non-fatal accidents',
  anyCommentsOnOccupationalDiseases: 'Any comments on occupational diseases',
  shortDescriptionOfTheRecordingAssessmentSystemForTheFatalAndNonFatalOccupationalAccidentsInForestry:
    'Short description of the recording/assessment system for the fatal and non-fatal occupational accidents in forestry',
  approachToReportingOnEnergyFromWood: 'Approach to reporting on energy from wood',
  approachToCalculateOrEstimateWoodDirectlyFromForestsAndOutsideForestsMarketedAndSelfConsumption:
    'Approach to calculate or estimate wood directly from forests and outside forests marketed and self-consumption',
  conversionFactorsUsedToConvertToEnergyFromEnergyUnits:
    'Conversion factors used to convert to energy from energy units',
  criteriaUsedToIncludeAreasInPrimarilyDesignatedOrManagedForPublicRecreation:
    'Criteria used to include areas in primarily designated or managed for public recreation',
  criteriaUsedToIncludeAreasInForestsAvailableToThePublicRecreation:
    'Criteria used to include areas in forests available to the public recreation',
  approachToReportingOnAccessibilityForRecreation: 'Approach to reporting on accessibility for recreation',
  areaOfForestsAvailableForPublicRecreation: 'Area of forests available for public recreation',
  areaOfForestsPrimarilyDesignatedOrManagedForPublicRecreation:
    'Area of forests primarily designated or managed for public recreation',
  inYourCountryAreRecentlyDegradedForestAreasLegallyConsideredAsNonForestLand:
    'In your country are recently degraded forest areas legally considered as non-forest land?',
  inYourCountryAreDegradedNonForestAreasReCategorisedToForestLandWithTheAimToReforestThem:
    'In your country are degraded non-forest areas re-categorised to forest land with the aim to reforest them?',
  christmasTreePlantationsOnForestLand: 'Christmas tree plantations on forest land',
  poplarPlantationsOnForestLand: 'Poplar plantations on forest land',
  shortRotationForestry: 'Short rotation forestry',
  shortRotationCoppicesOnForestLand: 'Short rotation coppices on forest land',
  OfWhichNotAvailableForWoodSupply: 'Of which not available for wood supply',
  environmentalRestrictions: 'Environmental restrictions',
  protectedForests: 'Protected forests',
  productiveForestsDesignatedToProtectKeyHabitatsSpeciesOrOtherNaturalValues:
    'Productive forests designated to protect key habitats/species or other natural values',
  protectiveForestsSoilWaterAndOtherEcosystemFunctionsInfrastructureAndManagedNaturalResources:
    'Protective forests (soil, water and other ecosystem functions, infrastructure and managed natural resources)',
  socialRestrictions: 'Social restrictions',
  forestsDesignatedToProtectAestheticHistoricalCulturalOrSpiritualValues:
    'Forests designated to protect aesthetic, historical, cultural or spiritual values',
  forestsManagedForRecreationalPurposes: 'Forests managed for recreational purposes',
  forestsExcludedFromHarvestingByOwnerSDecision: 'Forests excluded from harvesting by owner’s decision',
  militaryForests: 'Military forests',
  economicRestrictions: 'Economic restrictions',
  forestsOfVeryLowProductivityEconomicRevenueOfHarvesting:
    'Forests of very low productivity (economic revenue of harvesting)',
  forestsPhysicallyNotAccessibleIncludingSlopeAndSoilCondition:
    'Forests physically not accessible (including slope and soil condition)',
  otherPleaseSpecify: 'Other (please specify)',
  otherWoodedLand: 'Other wooded land',
  alpineShrublandEGPinusMugo: 'Alpine shrubland (e.g. Pinus mugo)',
  otherShrublandEGMaquisGarrigueMatorral: 'Other shrubland (e.g. maquis, garrigue, matorral)',
  otherLandWithTreeCover: 'Other land with tree cover',
  agroForestrySilvoPastoralAreasEGForRearingIberianBlackPig:
    'Agro-forestry/silvo-pastoral areas (e.g. for rearing Iberian black pig)',
  plantationsOfNutProducingTreesOrShrubsEGSweetChestnutsAlmondsWalnutsHazelnuts:
    'Plantations of nut-producing trees or shrubs (e.g. sweet chestnuts, almonds, walnuts, hazelnuts)',
  oliveGrovesAndFruitTreeOrchards: 'Olive groves and fruit tree orchards',
  poplarPlantationsOnAgriculturalLand: 'Poplar plantations on agricultural land',
  treesInUrbanSettings: 'Trees in urban settings',
  shortRotationCoppicesOnAgriculturalLand: 'Short rotation coppices on agricultural land',
  christmasTreePlantationsOnAgriculturalLand: 'Christmas tree plantations on agricultural land',
}
