// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryComments = require('./countryComments')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _2020 = require('./2020')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const _2025 = require('./2025')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const print = require('./print')

module.exports = {
  2020: _2020,
  2025: _2025,
  print,

  // assessment labels
  labels: {
    short: 'panEuropean',
  },

  countryComments,
  // ...TODO: Move all reasonability checks out to a separate file
  reasonabilityChecks: {
    reasonabilityCheck: 'Reasonableness check',
    description:
      'This section is to help national correspondents and reviewers verify data correctness and consistency across different reporting tables. It is non-editable and will not be published.',
    variable: 'Variable - Year',
    forest: 'Forest',
    FAWS: 'FAWS',
    OWL: 'OWL',
    FOWL: 'FOWL',

    netAnnualIncrementPerHa: 'Net annual increment per ha (3.1/1.1a) - {{year}}',
    fellingsPerHa: 'Fellings per ha (3.1/1.1a) - {{year}}',
    fellingsAsPct: 'Fellings as percent of net annual increment (3.1) - {{year}}',
    roundwoodRemovalAsPctOfGrowingStockSupply:
      'Roundwood removals as percent of growing stock on Forest available for wood supply (3.2 / 1.2a) - {{year}}',
    roundwoodRemovalAsPctOfGrowingStockTotal:
      'Roundwood removals as percent of growing stock on Forest and Other wooded land (3.2 / 1.2a) - {{year}}',
    growingStockPerHa: 'Growing stock per hectar (1.2a/1.1a) - {{year}}',
    carbonBelowByCarbonAbove: 'Carbon in below-ground biomass/ Carbon in above-ground biomass (1.4a) - {{year}}',
    carbonAboveByGrowingStock: 'Carbon in above-ground biomass/ growing stock (1.4a/1.2a) - {{year}}',
    soilCarbon: 'Soil carbon (1.4a/1.1a) - {{year}}',
    deadwoodVolumeComparedToCarbon: 'Dead wood: volume compared to carbon stock (4.5/1.4a) - {{year}}',
    totalWithDamageOverTotal: 'Area with damage as a share of Total forest area (2.4/1.1a) - {{year}}',
  },

  panEuCommentsTitle: 'Additional Comments',
  panEuropeanAssessment: 'Joint pan-European dataset',
  variable: 'Variable',
  landing: {
    link: 'Joint FOREST EUROPE / UNECE / FAO reporting on pan-European indicators for SFM : FOREST EUROPE',
  },
  home: {
    title: 'Joint Pan-European Data Collection',
    description1:
      'This platform provides comprehensive information on forests and the environmental, economic, and social dimensions of sustainable forest management in the Pan-European region (FOREST EUROPE signatory countries).',
    description2: `The Joint Pan-European Data Collection is the joint initiative by UNECE, FAO, and FOREST EUROPE, carried out in parallel to the FAO Global Forest Resources Assessment (FRA).`,
  },
  disclaimer: {
    platformDeveloped: `This platform was developed jointly by`,
    fao: 'FAO',
    unece: 'UNECE',
    technicalSupport: 'with the technical support of',
    and: 'and',
    forestEurope: 'FOREST EUROPE',
    govSwitzerland: 'Government of Switzerland',
    part1: 'The website has been produced with the assistance of the governments of',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
  },

  // navigation
  navigation: {
    forestResourcesAndCarbon: 'Forest resources and carbon',
    maintenanceOfForestEcosystemHealthAndVitality: 'Maintenance of forest ecosystem health and vitality',
    productiveFunctionsOfForestsWoodAndNonWood: 'Productive functions of forests (wood and non-wood)',
    biologicalDiversityInForestEcosystems: 'Biological diversity in forest ecosystems',
    protectiveFunctionsInForestManagement: 'Protective functions in forest management',
    socioEconomicFunctionsAndConditions: 'Socio-economic functions and conditions',
  },

  // table 1.1a
  forestArea: {
    forestArea: 'Forest area',
    forestAreaNumber: 'I. Forest area',
    forestAreaByForestTypeNumber: 'II. Forest area by forest type',
    categoryYear: 'Category - Year',
    area: 'Area',
    area1000Ha: 'Area (1 000 ha)',
    forest: 'Forest - {{year}}',
    _of_which_available_for_wood_supply: '… of which available for wood supply - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
    other_land: 'Other land - {{year}}',
    remaining_land_area: 'Remaining land area - {{year}}',
    _of_which_with_tree_cover: '… of which with tree cover - {{year}}',
  },

  // table 1.1b
  forestAreaByForestTypes: {
    forestAreaByForestTypes: 'Forest area by forest types',
    category: 'Category',
    forestArea1000Ha: 'Forest area (1 000 ha)',
    predominantly_coniferous_forest: 'Predominantly coniferous forest',
    predominantly_broadleaved_forest: 'Predominantly broadleaved forest',
    mixed_forest: 'Mixed forest',
    forest_area_1990: 'Forest area - 1990',
    forest_area_2000: 'Forest area - 2000',
    forest_area_2005: 'Forest area - 2005',
    forest_area_2010: 'Forest area - 2010',
    forest_area_2015: 'Forest area - 2015',
    forest_area_2020: 'Forest area - 2020',
  },

  // table 1.2a
  growingStock: {
    growingStockNumber: 'I. Growing stock',
    growingStockByForestTypeNumber: 'II. Growing stock by forest type',
    growingStockCompositionNumber: 'III. Growing stock composition',
    forestGrowingStockCompositionNumber: 'III. Forest growing stock composition',
    growingStock: 'Growing stock',
    categoryYear: 'Category - Year',
    growingStockMillionM3OB: 'Growing stock (million m³ over bark)',
    total: 'Total',
    _ofWhich: '… of which',
    coniferous: 'Coniferous',
    broadleaved: 'Broadleaved',
    forest: 'Forest - {{year}}',
    _of_which_available_for_wood_supply: '… of which available for wood supply - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
    total_forest_and_other_wooded_land_only: 'Total forest and other wooded land',
    of_which_available_for_wood_supply_only: 'of which available for wood supply',
    other_wooded_land_only: 'Other wooded land',
  },

  // table 1.2b
  growingStockByForestType: {
    growingStockByForestType: 'Growing stock by forest type',
    category: 'Category',
    growingStockMillionM3OB: 'Growing stock (million m³ over bark)',
    predominantly_coniferous_forest: 'Predominantly coniferous forest',
    predominantly_broadleaved_forest: 'Predominantly broadleaved forest',
    mixed_forest: 'Mixed forest',
    growing_stock_1990: 'Growing stock - 1990',
    growing_stock_2000: 'Growing stock - 2000',
    growing_stock_2005: 'Growing stock - 2005',
    growing_stock_2010: 'Growing stock - 2010',
    growing_stock_2015: 'Growing stock - 2015',
    growing_stock_2020: 'Growing stock - 2020',
  },

  // table 1.2c
  growingStockComposition: {
    growingStockComposition: 'Growing stock composition',
    speciesName: 'Species name',
    growingStockInForestMillionM3OB: 'Growing stock in forest (million m³ over bark)',
    rank: 'Rank',
    scientificName: 'Scientific name',
    commonName: 'Common name',
    no1_ranked_in_terms_of_volume: '#1 Ranked in terms of volume',
    no2_ranked_in_terms_of_volume: '#2 Ranked in terms of volume',
    no3_ranked_in_terms_of_volume: '#3 Ranked in terms of volume',
    no4_ranked_in_terms_of_volume: '#4 Ranked in terms of volume',
    no5_ranked_in_terms_of_volume: '#5 Ranked in terms of volume',
    no6_ranked_in_terms_of_volume: '#6 Ranked in terms of volume',
    no7_ranked_in_terms_of_volume: '#7 Ranked in terms of volume',
    no8_ranked_in_terms_of_volume: '#8 Ranked in terms of volume',
    no9_ranked_in_terms_of_volume: '#9 Ranked in terms of volume',
    no10_ranked_in_terms_of_volume: '#10 Ranked in terms of volume',
    noShort_ranked_in_terms_of_volume: '#1,#2,...,#10 Ranked in terms of volume',
    remaining: 'Remaining',
    total: 'TOTAL',
    scientific_name: 'Scientific name',
    common_name: 'Common name',
    growing_stock_in_forest_1990: 'Growing stock in forest (million m³ over bark) - 1990',
    growing_stock_in_forest_2000: 'Growing stock in forest (million m³ over bark) - 2000',
    growing_stock_in_forest_2005: 'Growing stock in forest (million m³ over bark) - 2005',
    growing_stock_in_forest_2010: 'Growing stock in forest (million m³ over bark) - 2010',
    growing_stock_in_forest_2015: 'Growing stock in forest (million m³ over bark) - 2015',
    growing_stock_in_forest_2020: 'Growing stock in forest (million m³ over bark) - 2020',
  },

  // table 1.3a1
  ageClassDistributionAreaOfEvenAgedStands: {
    ageClassDistributionAreaOfEvenAgedStands: 'Age class distribution (area of even-aged stands)',
    ageClassDistributionAreaOfEvenAgedStandsNumber: 'I. Age class distribution (area of even-aged stands)',
    ageClassDistributionNumber:
      'II. Age class distribution (volume of even-aged stands) in forest available for wood supply',
    categoryYear: 'Category - Year',
    developmentPhases: 'Development phases',
    total_area: 'Total area',
    regeneration_phase: 'Regeneration phase',
    intermediate_phase: 'Intermediate phase',
    mature_phase: 'Mature phase',
    unspecified: 'Unspecified',
    _1000Ha: '1 000 ha',
    forest: 'Forest',
    forest_even_aged: 'Forest (even-aged stands)',
    forest_uneven_aged: 'Forest (uneven-aged stands)',
    available_for_wood_supply: 'Available for wood supply',
    predominantly_coniferous_FAWS: 'Predominantly coniferous FAWS',
    predominantly_broadleaved_FAWS: 'Predominantly broadleaved FAWS',
    mixed: 'Mixed forest',
    forest_available_even_aged: 'Forest available for wood supply (even-aged stands)',
    forest_even_aged_stands_of_which: 'Forest (even-aged stands): - {{year}}',
    available_for_wood_supply_of_which: 'Available for wood supply: - {{year}}',
    predominantly_coniferous_forest: 'Predominantly coniferous FAWS - {{year}}',
    predominantly_broadleaved_forest: 'Predominantly broadleaved FAWS - {{year}}',
    mixed_forest: 'Mixed forest - {{year}}',
  },

  // table 1.3a2
  ageClassDistributionVolumeOfEvenAgedStandsInForestAvailableForWoodSupply: {
    ageClassDistributionVolumeOfEvenAgedStandsInForestAvailableForWoodSupply:
      'Age class distribution (volume of even-aged stands) in forest available for wood supply',
    categoryYear: 'Category - Year',
    developmentPhases: 'Development phases',
    total_volume: 'Total volume',
    regeneration_phase: 'Regeneration phase',
    intermediate_phase: 'Intermediate phase',
    mature_phase: 'Mature phase',
    unspecified: 'Unspecified',
    _1000M3: '1 000 m³',
    forest_available_for_wood_supply_even_aged_stands_of_which:
      'Forest available for wood supply (even-aged stands): - {{year}}',
    predominantly_coniferous_forest: 'Predominantly coniferous FAWS - {{year}}',
    predominantly_broadleaved_forest: 'Predominantly broadleaved FAWS - {{year}}',
    mixed_forest: 'Mixed forest - {{year}}',
  },

  // table 1.3b
  diameterDistributionAndTotalAreaUnevenAgedStands: {
    diameterDistributionAndTotalAreaUnevenAgedStands: 'Diameter distribution and total area (uneven-aged stands)',
    categoryYear: 'Category - Year',
    volumeByDiameterClasses1000mob: 'Volume by diameter classes (1 000 m³ over bark)',
    area: 'Area (1 000 ha)',
    total_volume: 'Total volume (1 000 m³ over bark)',
    less_or_equal_20_cm: '<20 cm',
    _21_40_cm: '21-40 cm',
    _41_60_cm: '41-60 cm',
    greater_60_cm: '>60 cm',
    unspecified: 'Unspecified',
    forest: 'Forest',
    forest_uneven_aged_stands: 'Forest uneven-aged stands - {{year}}',
    _of_which_forest_available_for_wood_supply: '... of which: Forest available for wood supply - {{year}}',
  },

  // table 1.4a
  carbonStock: {
    carbonStock: 'Carbon stock',
    carbonStockNumber: 'I. Carbon stock',
    carbonStockInHarvestedWoodProductsHWPNumber: 'II. Carbon stock in harvested wood products (HWP)',
    categoryYear: 'Category - Year',
    carbonInAboveGroundAndBelowGroundLivingBiomass: 'Carbon in above-ground and below-ground living biomass',
    carbonInDeadwoodAndLitter: 'Carbon in deadwood and litter',
    above_ground: 'Carbon in above-ground living biomass',
    aboveGround: 'Above-ground biomass',
    below_ground: 'Carbon in below-ground living biomass',
    belowGround: 'Below-ground biomass',
    deadwood: 'Carbon in deadwood',
    deadwoodCarbon: 'Deadwood',
    litter: 'Carbon in litter',
    litterCarbon: 'Litter',
    soil_carbon: 'Soil carbon',
    millionMetricTonnes: 'Million metric tonnes',
    carbonMillionMetricTonnes: 'Forest Carbon (million metric tonnes)',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 1.4b
  carbonStockInHarvestedWoodProductsHWP: {
    carbonStockInHarvestedWoodProductsHWP: 'Carbon stock in harvested wood products (HWP)',
    categoryYear: 'Category - Year',
    totalCarbonStockInHWPMillionMetricTonnes: 'Total carbon stock in HWP (million metric tonnes)',
    total_carbon_stock_in_hwp: 'Total carbon stock in HWP',
    harvested_wood_products: 'Harvested wood products - {{year}}',
  },

  // table 2.4
  forestAreaWithDamage: {
    forestAreaWithDamage: 'Forest area with damage',
    categoryYear: 'Category - Year',
    areaDamageExcFire: 'Area with damage by different agents (excluding fire)',
    areaDamageExcFireHa: 'Area with damage by different agents (excluding fire) (1 000ha)',
    areaWithDamageByDifferentAgents: 'Area with damage by different agents',
    total: 'Total',
    primarilyDamagedByBioticAgents: 'Primarily damaged by biotic agents',
    damagePrimarilyHumanInduced: 'Damage primarily human induced',
    primarilyDamagedByFire: 'Primarily damaged by fire',
    primarilyDamagedByFireHa: 'Primarily damaged by fire (1 000ha)',
    thousandHa: '1 000 ha',
    affectedThousandHa: 'Forest area affected (1 000 ha)',
    total_area_with_damage: 'Total area with damage',
    insects_and_disease: 'Biotic agents: Insects and disease',
    insectsAndDisease: 'Insects and disease',
    disease: 'Disease',
    insects: 'Insects',
    wildlife_and_grazing: 'Biotic agents: Wildlife and grazing',
    wildlifeAndGrazing: 'Wildlife and grazing',
    forest_operations: 'Human induced: Forest operations',
    forestOperations: 'Forest operations',
    other: 'Human induced: Other',
    otherHumanInduced: 'Other',
    primarily_damaged_by_abiotic_agents: 'Abiotic agents (storm, wind, snow, etc.)',
    primarilyDamagedByAbioticAgents: 'Primarily damaged by abiotic agents (storm, wind, snow, etc.)',
    primarily_damaged_by_fire_total: 'Fire: Total',
    primarilyDamagedByFireTotal: 'Total',
    of_which_human_induced: 'Fire: Of which human induced',
    ofWhichHumanInduced: '... of which: human induced',
    unspecifiedMixedDamage: 'Unspecified / Mixed damage',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 2.5
  areaWithForestLandDegradation: {
    areaWithForestLandDegradation: 'Area with forest land degradation',
    categoryYear: 'Category - Year',
    totalAreaOfDegradedLand: 'Total area of degraded land (1 000 ha)',
    totalAreaOfDegradedLand2025: 'Total degraded forest area (1 000 ha)',
    areaPrimarilyDegradedBy: 'Area primarily degraded by (1 000 ha)',
    formerDegradedLandRestored: 'Former degraded land restored (1 000 ha)',
    grazing: 'Grazing',
    agentOne: '',
    agentTwo: '',
    agentThree: '',
    agentFour: '',
    agentFive: '',
    repeatedFires: 'Repeated fires',
    airPollution: 'Air pollution',
    desertification: 'Desertification',
    other1: 'Other 1',
    other2: 'Other 2',
    other3: 'Other 3',
    unknownMixedDegradation: 'Unknown / Mixed degradation',
    unknownMixedDegradation2025: 'Unknown agent / Mixed degradation (specify in comments)',
    nA: '',
    agentName: '',
    writeName: 'Write Agent Name...',
    thousandHa: '1 000 ha',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
    otherNames: 'The names of the other factors that area is primarily degraded by',
    total_area_of_degraded_land: 'Total area of degraded land',
    repeated_fires: 'Repeated fires',
    air_pollution: 'Air pollution',
    other_1: 'Other 1',
    other_2: 'Other 2',
    other_3: 'Other 3',
    former_degraded_land_restored: 'Former degraded land restored',
  },

  // table 3.1
  incrementAndFellings: {
    incrementAndFellingsVolume1000mob: 'Increment and fellings volume (1 000 m³ over bark)',
    incrementAndFellings: 'Increment and fellings',
    categoryYear: 'Category - Year',
    fellings: 'Fellings',
    volume1000mob: 'Volume (1 000 m³ over bark)',
    gross_annual_increment: 'Gross annual increment',
    natural_losses: 'Natural losses',
    net_annual_increment: 'Net annual increment',
    fellings_total: 'Fellings: Total',
    fellingsTotal: 'Total',
    _of_which_of_natural_losses: 'Felling: Natural losses',
    ofWhichOfNaturalLosses: '... of which: of natural losses',
    forest: 'Forest - {{year}}',
    _of_which_forest_available_for_wood_supply: '... of which: Forest available for wood supply - {{year}}',
  },

  // table 3.2
  removals: {
    removals: 'Removals',
    categoryYear: 'Category - Year',
    woodRemovals: 'Wood removals',
    total: 'Total',
    industrialRoundwood: 'Industrial roundwood',
    woodfuel: 'Woodfuel',
    volume1000M3UB: 'Volume (1 000 m³ under bark)',
    marketValue1000NationalCurrency: 'Market Value (1 000  national currency)',
    roundwood: 'Roundwood - {{year}}',
    total_volume: 'Total: Volume (1 000 m³ under bark)',
    industrial_roundwood_volume: 'Industrial roundwood: Volume (1 000 m³ under bark)',
    industrial_roundwood_market_value: 'Industrial roundwood: Market Value (1 000  national currency)',
    woodfuel_volume: 'Woodfuel: Volume (1 000 m³ under bark)',
    woodfuel_market_value: 'Woodfuel: Market Value (1 000  national currency)',
  },

  nonWoodGoods: {
    nonWoodGoods: 'Non-wood goods',
  },

  marketedServices: {
    marketedServices: 'Marketed services',
  },

  // table 3.3
  nonWoodGoods2015: {
    nonWoodGoods2015: 'Non-wood goods (2015)',
    nonWoodGoods: 'Non-wood goods',
    rankValue: 'Rank (value)',
    nameOfGroupsOfProduct: 'Name of (groups of) product',
    keySpecies: 'Key species',
    totalHarvestedNonWoodGoods: 'Total harvested non-wood goods',
    unit: 'Unit',
    quantity: 'Quantity',
    marketValue1000NationalCurrency: 'Market value (1 000 national currency)',
    nwfpCategory: 'NWFP category',
    _01st: '1st Rank (value)',
    _02nd: '2nd Rank (value)',
    _03rd: '3rd Rank (value)',
    _04th: '4th Rank (value)',
    _05th: '5th Rank (value)',
    _06th: '6th Rank (value)',
    _07th: '7th Rank (value)',
    _08th: '8th Rank (value)',
    _09th: '9th Rank (value)',
    _10th: '10th Rank (value)',
    all_other_plant_products: 'All other plant products',
    all_other_animal_products: 'All other animal products',
    total: 'TOTAL',
    name_of_groups_of_product: 'Name of (groups of) product',
    key_species: 'Key species',
    total_harvested_non_wood_goods_unit: 'Total harvested non-wood goods: Unit',
    total_harvested_non_wood_goods_quantity: 'Total harvested non-wood goods: Quantity',
    market_value_1000_national_currency: 'Market value (1 000 national currency)',
    nwfp_category: 'NWFP category',
  },

  // table 3.4
  marketedServices2015: {
    marketedServices2015: 'Marketed services (2015)',
    marketedServices: 'Marketed services',
    rankValue: 'Rank (value)',
    nameOfServiceProduct: 'Name of service/product',
    unit: 'Unit',
    serviceProvision: 'Service provision',
    forestServiceCategory: 'Forest service category',
    amountOfServiceProduct: 'Amount of service/product',
    value1000NationalCurrency: 'Value (1 000 national currency)',
    _01st: '1st Rank (value)',
    _02nd: '2nd Rank (value)',
    _03rd: '3rd Rank (value)',
    _04th: '4th Rank (value)',
    _05th: '5th Rank (value)',
    _06th: '6th Rank (value)',
    _07th: '7th Rank (value)',
    _08th: '8th Rank (value)',
    _09th: '9th Rank (value)',
    _10th: '10th Rank (value)',
    remaining_total: 'Remaining total',
    total: 'Total',
    name_of_service_product: 'Name of service/product',
    service_provision_amount_of_service_product: 'Service provision: Amount of service/product',
    service_provision_value_1000_national_currency: 'Service provision: Value (1 000 national currency)',
    forest_service_category: 'Forest service category',
  },

  // table 4.1
  treeSpeciesComposition: {
    treeSpeciesComposition: 'Tree species composition',
    categoryYear: 'Category - Year',
    areaWithNumberOfTreeSpeciesOccurring1000ha: 'Area with number of tree species occurring (1 000 ha)',
    area_with_number_of_tree_species_occurring_1: 'Area with number of tree species occurring: 1',
    areaWithNumberOfTreeSpeciesOccurring1: '1',
    area_with_number_of_tree_species_occurring_2_3: 'Area with number of tree species occurring: 2-3',
    areaWithNumberOfTreeSpeciesOccurring2_3: '2-3',
    area_with_number_of_tree_species_occurring_4_5: 'Area with number of tree species occurring: 4-5',
    areaWithNumberOfTreeSpeciesOccurring4_5: '4-5',
    area_with_number_of_tree_species_occurring_6_pl: 'Area with number of tree species occurring: 6+',
    areaWithNumberOfTreeSpeciesOccurring6_pl: '6+',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 4.2a
  totalForestAreaByExpansionAndRegenerationType: {
    forestAreaByExpansionAndRegenerationSubcategories: 'Forest area by expansion and regeneration subcategories',
    totalForestAreaByExpansionAndRegenerationType: 'Total forest area by expansion and regeneration type',
    totalForestAreaByExpansionAndRegenerationTypeNumber: 'I. Total forest area by expansion and regeneration type',
    annualForestExpansionAndRegenerationNumber: 'II. Annual forest expansion and regeneration',
    categoryYear: 'Category - Year',
    totalAreaOfForestByExpansionRegenerationType1000ha:
      'Total area of forest by expansion/regeneration type (1 000 ha)',
    natural_expansion_and_natural_regeneration: 'Natural expansion and natural regeneration',
    afforestation_and_regeneration_by_planting_and_or_seeding:
      'Afforestation and regeneration by planting and/or seeding',
    coppice: '... of which: Coppice',
    forest: 'Forest - {{year}}',
  },

  // table 4.2b
  annualForestExpansionAndRegeneration: {
    annualForestExpansionAndRegeneration: 'Annual forest expansion and regeneration',
    categoryYear: 'Category - Year',
    annualForestExpansionAndRegeneration1000ha: 'Annual forest expansion and regeneration (1 000 ha / year)',
    expansionOfForestArea: 'Expansion of forest area',
    regenerationOfForestArea: 'Regeneration of forest area',
    afforestation: 'Expansion: Afforestation',
    afforestationExpansion: 'Afforestation',
    natural_expansion: 'Expansion: Natural expansion',
    naturalExpansion: 'Natural expansion',
    natural_regeneration: 'Regeneration: Natural regeneration',
    naturalRegeneration: 'Natural regeneration',
    planting_and_seeding: 'Regeneration: Planting and seeding',
    plantingAndSeeding: 'Planting and seeding',
    coppice: 'Regeneration: Coppice',
    coppiceRegeneration: 'Coppice',
    forest: 'Forest - {{year}}',
  },

  // table 4.3a
  naturalness: {
    naturalness: 'Naturalness',
    naturalnessNumber: 'I. Naturalness',
    naturalnessBySubclassNumber: 'II. Naturalness by subclass',
    categoryYear: 'Category - Year',
    area1000ha: 'Area (1 000 ha)',
    undisturbed_by_man: 'Undisturbed by man',
    semi_natural: 'Semi-natural',
    plantations: 'Plantations',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 4.3b
  naturalnessBySubclasses: {
    naturalnessBySubclasses: 'Naturalness by subclasses',
    categoryYear: 'Category - Year',
    area1000ha: 'Area (1 000 ha)',
    semiNatural: 'Semi-natural',
    semiNaturalSubclasses: 'Semi-natural subclasses',
    plantations: 'Plantations',
    naturally_established: 'Semi-natural: Naturally established',
    naturallyEstablished: 'Naturally established',
    naturalised_introduced_species: 'Semi-natural: Naturalised introduced species',
    naturalisedIntroducedSpecies: 'Naturalised introduced species',
    established_by_planting_and_or_seeding: 'Semi-natural: Established by planting and/or seeding',
    establishedByPlantingAndOrSeeding: 'Established by planting and/or seeding',
    coppice: 'Semi-natural: Coppice',
    coppiceSemiNatural: 'Coppice',
    unknown_origin: 'Semi-natural: Unknown origin',
    unknownOrigin: 'Unknown origin',
    native_species: 'Plantations: Native species',
    nativeSpecies: 'Native species',
    introduced_species: 'Plantations: Introduced species',
    introducedSpecies: 'Introduced species',
    forest: 'Forest - {{year}}',
  },

  // table 4.4a
  introducedTreeSpecies: {
    introducedTreeSpecies: 'Area of stands dominated by introduced tree species',
    introducedTreeSpeciesNumber: 'I. Area of stands dominated by introduced tree species',
    introducedTreeSpecies4_4bNumber: 'II. Introduced tree species',
    invasiveTreeSpeciesNumber: 'III. Invasive tree species',
    categoryYear: 'Category - Year',
    areaOfStandsDominatedByIntroducedTreeSpecies1000ha:
      'Area of stands dominated by introduced tree species (1 000 ha)',
    total: 'Total',
    _of_which_invasive: '… of which: invasive',
    forest: 'Forest - {{year}}',
    forest_only: 'Forest',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 4.4b
  introducedTreeSpecies4_4b: {
    introducedTreeSpecies4_4b: 'Introduced tree species',
    category: 'Category',
    scientificNameOfIntroducedTreeSpecies: 'Scientific name of introduced tree species',
    forestAreaOccupied1000Ha: 'Forest area occupied (1 000 ha)',
    _01: 'Introduced tree species 1',
    _02: 'Introduced tree species 2',
    _03: 'Introduced tree species 3',
    _04: 'Introduced tree species 4',
    _05: 'Introduced tree species 5',
    _06: 'Introduced tree species 6',
    _07: 'Introduced tree species 7',
    _08: 'Introduced tree species 8',
    _09: 'Introduced tree species 9',
    _10: 'Introduced tree species 10',
    _11: 'Introduced tree species 11',
    _12: 'Introduced tree species 12',
    _13: 'Introduced tree species 13',
    _14: 'Introduced tree species 14',
    _15: 'Introduced tree species 15',
    _16: 'Introduced tree species 16',
    _17: 'Introduced tree species 17',
    _18: 'Introduced tree species 18',
    _19: 'Introduced tree species 19',
    _20: 'Introduced tree species 20',
    scientific_name_of_introduced_tree_species: 'Scientific name of introduced tree species',
    forest_area_occupied_2005: 'Forest area occupied (1 000 ha) - 2005',
    forest_area_occupied_2010: 'Forest area occupied (1 000 ha) - 2010',
    forest_area_occupied_2015: 'Forest area occupied (1 000 ha) - 2015',
  },

  // table 4.4c
  invasiveTreeSpecies: {
    invasiveTreeSpecies: 'Invasive tree species',
    category: 'Category',
    scientificNameOfInvasiveTreeSpecies: 'Scientific name of invasive tree species',
    forestAreaAffected1000Ha: 'Forest area affected (1 000 ha)',
    _01: 'Invasive tree species 1',
    _02: 'Invasive tree species 2',
    _03: 'Invasive tree species 3',
    _04: 'Invasive tree species 4',
    _05: 'Invasive tree species 5',
    _06: 'Invasive tree species 6',
    _07: 'Invasive tree species 7',
    _08: 'Invasive tree species 8',
    _09: 'Invasive tree species 9',
    _10: 'Invasive tree species 10',
    _11: 'Invasive tree species 11',
    _12: 'Invasive tree species 12',
    _13: 'Invasive tree species 13',
    _14: 'Invasive tree species 14',
    _15: 'Invasive tree species 15',
    _16: 'Invasive tree species 16',
    _17: 'Invasive tree species 17',
    _18: 'Invasive tree species 18',
    _19: 'Invasive tree species 19',
    _20: 'Invasive tree species 20',
    scientific_name_of_invasive_tree_species: 'Scientific name of invasive tree species',
    forest_area_affected_2005: 'Forest area affected (1 000 ha) - 2005',
    forest_area_affected_2010: 'Forest area affected (1 000 ha) - 2010',
    forest_area_affected_2015: 'Forest area affected (1 000 ha) - 2015',
  },

  // table 4.5
  deadwood: {
    deadwood: 'Deadwood',
    categoryYear: 'Category - Year',
    volumeOfDeadwoodM3Ha: 'Volume of deadwood (m³/ha)',
    total: 'Total',
    standing: 'Standing',
    lying: 'Lying',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
    volumeOfDeadwoodInFOWLBySpeciesGroups: 'Volume of deadwood in FOWL by species groups',
    coniferous: 'Coniferous - {{year}}',
    broadleaved: 'Broadleaved - {{year}}',
  },

  // table 4.8
  threatenedForestSpecies: {
    threatenedForestSpecies: 'Threatened forest species',
    categoryYear: 'Category - Year',
    threatenedForestSpeciesCol: 'Threatened forest species',
    threatenedForestSpeciesColAbs: 'Forest Species (Absolute Number)',
    absoluteNumber: 'Absolute number',
    total_of_taxa: 'Total of taxa',
    vulnerable: 'Vulnerable',
    endangered: 'Endangered',
    critically_endangered: 'Critically endangered',
    extinct_in_the_wild: 'Extinct in the wild',
    trees: 'Trees - {{year}}',
    birds: 'Birds - {{year}}',
    mammals: 'Mammals - {{year}}',
    other_vertebrates: 'Other vertebrates - {{year}}',
    invertebrates: 'Invertebrates - {{year}}',
    vascular_plants: 'Vascular plants - {{year}}',
    cryptogams_and_fungi: 'Cryptogams and fungi - {{year}}',
  },

  // table 4.9
  protectedForests: {
    protectedForests: 'Protected forests',
    categoryYear: 'Category - Year',
    ha1000: 'Area (1 000 ha)',
    mcpfe_class_1_1: 'MCPFE Class 1.1',
    mcpfe_class_1_2: 'MCPFE Class 1.2',
    mcpfe_class_1_3: 'MCPFE Class 1.3',
    mcpfe_class_2: 'MCPFE Class 2',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 5.1
  protectiveForestsSoilWaterAndOtherEcosystemFunctions: {
    protectiveForestsSoilWaterAndOtherEcosystemFunctions:
      'Protective forests – soil, water and other ecosystem functions; infrastructure and managed natural resources',
    categoryYear: 'Category - Year',
    protectiveForestsMCPFEClass31000ha: 'MCPFE Class 3 Area (1 000 ha)',
    soil_water_and_other_forest_ecosystem_functions: 'Soil, water and other forest ecosystem functions',
    infrastructure_and_managed_natural_resources: 'Infrastructure and managed natural resources',
    total: 'Total',
    forest: 'Forest - {{year}}',
    other_wooded_land: 'Other wooded land - {{year}}',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 6.1
  forestHoldings: {
    forestHoldings: 'Forest holdings',
    categoryYear: 'Category - Year',
    areaAndNumberOfForestHoldingsInSizeClasses: 'Area and number of forest holdings in size classes',
    less10ha: '≤ 10 ha',
    _11_500ha: '11 - 500 ha',
    more500ha: '≥ 500 ha',
    total_forest_area: 'Total forest area (1 000 ha)',
    total_number_of_holdings: 'Total number of holdings',
    less_10_ha_area: '≤ 10 ha: Area (1 000 ha)',
    less_10_ha_number: '≤ 10 ha: Number of holdings',
    _11_500_ha_area: '11 - 500 ha: Area (1 000 ha)',
    _11_500_ha_number: '11 - 500 ha: Number of holdings',
    more_500_ha_area: '≥ 500 ha: Area (1 000 ha)',
    more_500_ha_number: '≥ 500 ha: Number of holdings',
    area1000Ha: 'Area (1 000 ha)',
    numberOfHoldings: 'Number of holdings',
    in_public_ownership: 'In public ownership - {{year}}',
    public_ownership: 'In public ownership',
    in_private_ownership: 'In private ownership - {{year}}',
    private_ownership: 'In private ownership',
    other_types_of_ownership_unknown: 'Other types of ownership/unknown - {{year}}',
    other: 'Other types of ownership/unknown',
    forest: 'Forest',
  },

  // table 6.2
  grossValueAdded: {
    grossValueAdded: 'Gross value added',
    categoryYear: 'Category - Year',
    grossValueAddedCol: 'Gross value added',
    million_national_currency: 'Million national currency',
    percent_of_total_gva: '% of total GVA',
    forestry: 'Forestry (ISIC/NACE 02) - {{year}}',
    manufacture_of_wood_and_articles_in_wood: 'Manufacture of wood and articles in wood (ISIC/NACE 16) - {{year}}',
    manufacture_of_paper_and_paper_products: 'Manufacture of paper and paper products (ISIC/NACE 17) - {{year}}',
  },

  // table 6.3
  factorIncomeAndEntrepreneurialIncome: {
    factorIncomeAndEntrepreneurialIncome: 'Factor income and entrepreneurial income',
    categoryYear: 'Category - Year',
    millionNationalCurrency: 'Million national currency',
    factor_income: 'Factor income',
    net_operating_surplus: 'Net operating surplus',
    forestry: 'Forestry (ISIC/NACE 02) - {{year}}',
  },

  // table 6.4a
  totalGrossFixedCapitalFormationInForestsAndForestry: {
    totalGrossFixedCapitalFormationInForestsAndForestry: 'Total gross fixed capital formation in forests and forestry',
    categoryYear: 'Category - Year',
    grossFixedCapitalFormationMillionNationalCurrency: 'Gross fixed capital formation (million national currency)',
    plantingOfTreesToProvideRegularIncome: 'Planting of trees to provide regular income',
    equipmentAndBuildings: 'Equipment and buildings',
    otherGrossFixedCapitalFormation: 'Other Gross fixed capital formation',
    total: 'Total',
    forestry_isic_nace_02: 'Forestry (ISIC/NACE 02) - {{year}}',
    planting_of_trees_to_provide_regular_income: 'Planting of trees to provide regular income',
    equipment_and_buildings: 'Equipment and buildings',
    other_gross_fixed_capital_formation: 'Other Gross fixed capital formation',
  },

  // table 6.4b
  totalFixedCapitalConsumptionInForestsAndForestry: {
    totalFixedCapitalConsumptionInForestsAndForestry: 'Total fixed capital consumption in forests and forestry',
    categoryYear: 'Category - Year',
    fixedCapitalConsumptionMillionNationalCurrency: 'Fixed capital consumption (million national currency)',
    fixed_capital_consumption: 'Fixed capital consumption',
    forestry_isic_nace_02: 'Forestry (ISIC/NACE 02) - {{year}}',
  },

  // table 6.4c
  totalCapitalTransfersInForestsAndForestry: {
    totalCapitalTransfersInForestsAndForestry: 'Total capital transfers in forests and forestry',
    categoryYear: 'Category - Year',
    capitalTransfersMillionNationalCurrency: 'Capital transfers (million national currency)',
    capital_transfers: 'Capital transfers',
    forestry_isic_nace_02: 'Forestry (ISIC/NACE 02) - {{year}}',
  },

  // table 6.5a
  employmentByGenderAndAge: {
    employmentByGenderAge1000p: 'Employment by gender and age (1 000 people)',
    employmentByGenderAndAge: 'Employment by gender and age',
    categoryYear: 'Category - Year',
    total: 'Total',
    gender: 'Gender',
    ageGroup: 'Age group',
    male: 'Male',
    female: 'Female',
    _15_49: '15-49',
    _50_: '50+',
    _1000Persons: '1 000 people',
    forestry: 'Forestry (ISIC/NACE 02) - {{year}}',
    manufacture_of_wood_and_articles_in_wood: 'Manufacture of wood and articles in wood (ISIC/NACE 16) - {{year}}',
    manufacture_of_paper_and_paper_products: 'Manufacture of paper and paper products (ISIC/NACE 17) - {{year}}',
    gender_male: 'Gender: Male',
    gender_female: 'Gender: Female',
    age_group_15_49: 'Age group: 15-49',
    age_group_50_plus: 'Age group: 50+',
  },

  // table 6.5b
  employmentByEducationAndJobCharacteristics: {
    employmentByEducationAndJobCharacteristics: 'Employment by education and job characteristics',
    categoryYear: 'Category - Year',
    education: 'Education (Categories ISCED 1997)',
    jobCharacteristics: 'Job characteristics',
    _0_2: '0-2',
    _3_4: '3-4',
    _5_6: '5-6',
    _1000Persons: '1 000 people',
    education_0_2: 'Education (Categories ISCED 1997): 0-2',
    education_3_4: 'Education (Categories ISCED 1997): 3-4',
    education_5_6: 'Education (Categories ISCED 1997): 5-6',
    employees: 'Job characteristics: Employees',
    employeesJobCharacteristics: 'Employees',
    self_employed: 'Job characteristics: Self-employed',
    selfEmployed: 'Self-employed',
    forestry: 'Forestry (ISIC/NACE 02) - {{year}}',
    manufacture_of_wood_and_articles_in_wood: 'Manufacture of wood and articles in wood (ISIC/NACE 16) - {{year}}',
    manufacture_of_paper_and_paper_products: 'Manufacture of paper and paper products (ISIC/NACE 17) - {{year}}',
  },

  // table 6.6
  occupationalAccidents: {
    occupationalAccidents: 'Occupational accidents',
    categoryYear: 'Category - Year',
    fatalOccupationalAccidents: 'Fatal occupational accidents',
    nonFatalOccupationalAccidents: 'Non-fatal occupational accidents',
    number: 'Number',
    annualRatePer1000Workers: 'Annual rate per 1 000 workers',
    fatal_occupational_accidents_number: 'Fatal occupational accidents: Number',
    fatal_occupational_accidents_per_1000_workers: 'Fatal occupational accidents: Annual rate per 1 000 workers',
    non_fatal_occupational_accidents_number: 'Non-fatal occupational accidents: Number',
    non_fatal_occupational_accidents_per_1000_workers:
      'Non-fatal occupational accidents: Annual rate per 1 000 workers',
    forestry_isic_nace_02: 'Forestry (ISIC/NACE 02) - {{year}}',
  },

  // table 6.7
  woodConsumption: {
    woodConsumption: 'Wood consumption',
    category: 'Category',
    woodConsumption1000M3RWE: 'Wood consumption (1 000 m³ round-wood equivalent)',
    _1992: '1992',
    _1993: '1993',
    _1994: '1994',
    _1995: '1995',
    _1996: '1996',
    _1997: '1997',
    _1998: '1998',
    _1999: '1999',
    _2000: '2000',
    _2001: '2001',
    _2002: '2002',
    _2003: '2003',
    _2004: '2004',
    _2005: '2005',
    _2006: '2006',
    _2007: '2007',
    _2008: '2008',
    _2009: '2009',
    _2010: '2010',
    _2011: '2011',
    _2012: '2012',
    _2013: '2013',
    _2014: '2014',
    _2015: '2015',
    _2016: '2016',
    _2017: '2017',
    wood_consumption: 'Consumption of forest products',
  },

  // table 6.8
  tradeInWood: {
    tradeInWood: 'Trade in wood',
    category: 'Category',
    _1992: '1992',
    _1993: '1993',
    _1994: '1994',
    _1995: '1995',
    _1996: '1996',
    _1997: '1997',
    _1998: '1998',
    _1999: '1999',
    _2000: '2000',
    _2001: '2001',
    _2002: '2002',
    _2003: '2003',
    _2004: '2004',
    _2005: '2005',
    _2006: '2006',
    _2007: '2007',
    _2008: '2008',
    _2009: '2009',
    _2010: '2010',
    _2011: '2011',
    _2012: '2012',
    _2013: '2013',
    _2014: '2014',
    _2015: '2015',
    _2016: '2016',
    _2017: '2017',
    exports_of_forest_products_quantity: 'Exports of forest products (million m³ round-wood equivalent)',
    exports_of_forest_products_value: 'Exports of forest products (million Euro/ECU)',
    imports_of_forest_products_quantity: 'Imports of forest products (million m³ round-wood equivalent)',
    imports_of_forest_products_value: 'Imports of forest products (million Euro/ECU)',
  },

  // table 6.9
  totalEnergySupplyFromWood: {
    totalEnergySupplyFromWood: 'Total energy supply from wood',
    category: 'Category',
    tj: 'TJ',
    _1000_metric_tonnes_dry_matter: '1 000 metric tonnes dry matter',
    total_primary_energy_supply: 'Total primary energy supply',
    total_renewable_energy_supply: 'Total renewable energy supply',
    total_energy_supply_from_wood: 'Total energy supply from wood:',
    energy_from_direct_wood_fibre_sources: '- Energy from direct wood fibre sources:',
    of_which_from_forests: 'of which from forests',
    of_which_from_other_wooded_land: 'of which from other wooded land and other land (trees outside forests)',
    energy_from_co_products: '- Energy from co-products and residues of the wood processing industries:',
    of_which_solid_residues:
      'of which solid residues (chips, particles, wood residues, bark, excluding processed wood-based fuels)',
    energy_from_processed_wood_based_fuels: '- Energy from processed wood-based fuels (pellets, briquettes, charcoal):',
    of_which_imported: 'of which imported',
    energy_from_post_consumer_recovered_wood: '- Energy from post-consumer recovered wood',
    energy_from_unknown_unspecified_sources: '- Energy from unknown/unspecified sources',
    tj_2007: '2007: TJ',
    tj_2009: '2009: TJ',
    tj_2011: '2011: TJ',
    tj_2013: '2013: TJ',
    tj_2015: '2015: TJ',
    _1000_metric_tonnes_dry_matter_2007: '2007: 1 000 metric tonnes dry matter',
    _1000_metric_tonnes_dry_matter_2009: '2009: 1 000 metric tonnes dry matter',
    _1000_metric_tonnes_dry_matter_2011: '2011: 1 000 metric tonnes dry matter',
    _1000_metric_tonnes_dry_matter_2013: '2013: 1 000 metric tonnes dry matter',
    _1000_metric_tonnes_dry_matter_2015: '2015: 1 000 metric tonnes dry matter',
  },

  // table 6.10a
  accessibilityForRecreation: {
    accessibilityForRecreation: 'Accessibility for recreation',
    accessibilityForRecreationNumber: 'I. Accessibility for recreation',
    intensityOfUseNumber: 'II. Intensity of use',
    recreationFacilitiesNumber: 'III. Recreation facilities',
    otherRecreationFacilitiesNumber: 'IV. Other recreation facilities - pilot reporting (2020)',
    categoryYear: 'Category - Year',
    areaAvailableForPublicRecreation: 'Area available for public recreation',
    total1000Ha: 'Area (1 000 ha)',
    _oftotal: '% of total',
    areaPrimarilyDesignatedOrManagedForPublicRecreation: 'Area primarily designated or managed for public recreation',
    area_available_for_public_recreation_total: 'Area available for public recreation: Total (1 000 ha)',
    area_available_for_public_recreation_percent: 'Area available for public recreation: % of total',
    area_designated_or_managed_for_public_recreation_total:
      'Area primarily designated or managed for public recreation: Total (1 000 ha)',
    area_designated_or_managed_for_public_recreation_percent:
      'Area primarily designated or managed for public recreation: % of total',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
  },

  // table 6.10b
  intensityOfUse: {
    intensityOfUse: 'Intensity of use',
    categoryYear: 'Category - Year',
    annualNumberOfVisitsMillion: 'Annual number of visits (million)',
    areaAvailableForPublicRecreation: 'Area available for public recreation',
    areaPrimarilyDesignatedAndOrManagedForPublicRecreation:
      'Area primarily designated and/or managed for public recreation',
    total_forest_and_other_wooded_land: 'Total forest and other wooded land - {{year}}',
    area_available_for_public_recreation: 'Area available for public recreation',
    area_designated_and_or_managed_for_public_recreation:
      'Area primarily designated and/or managed for public recreation',
  },

  // table 6.10c
  recreationFacilities: {
    recreationFacilities: 'Recreation facilities',
    categoryYear: 'Category - Year',
    forestRoadsAndPathsAvailableForPublicRecreation: 'Forest roads and paths available for public recreation',
    _ofWhichDesignatedForHikingBikingCrossCountrySkiingEtc:
      '... of which designated for: hiking, biking, cross country skiing, etc.',
    facilityLengthIn1000Km: 'Facility (length in 1 000 km)',
    area_available_for_public_recreation: 'Area available for public recreation - {{year}}',
    forest_roads_and_paths_available_for_public_recreation: 'Forest roads and paths available for public recreation',
    _of_which_designated_for_hiking_biking_cross_country_skiing_etc:
      '... of which designated for hiking, biking, cross country skiing, etc.',
  },

  // table 6.10d
  otherRecreationFacilitiesPilotReporting2015: {
    otherRecreationFacilitiesPilotReporting2015: 'Other recreation facilities - pilot reporting (2015)',
    category: 'Category',
    facility: 'Facility',
    measurement_unit: 'Measurement unit',
    extent_multiplicity: 'Extent / Multiplicity',
    facility_category: 'Facility category',
    no1_area_available_for_public_recreation: 'Area available for public recreation 1',
    no2_area_available_for_public_recreation: 'Area available for public recreation 2',
    no3_area_available_for_public_recreation: 'Area available for public recreation 3',
    no4_area_available_for_public_recreation: 'Area available for public recreation 4',
    no5_area_available_for_public_recreation: 'Area available for public recreation 5',
    no6_area_available_for_public_recreation: 'Area available for public recreation 6',
    no7_area_available_for_public_recreation: 'Area available for public recreation 7',
    no8_area_available_for_public_recreation: 'Area available for public recreation 8',
    no9_area_available_for_public_recreation: 'Area available for public recreation 9',
    no10_area_available_for_public_recreation: 'Area available for public recreation 10',
    no11_area_available_for_public_recreation: 'Area available for public recreation 11',
    no12_area_available_for_public_recreation: 'Area available for public recreation 12',
    no13_area_available_for_public_recreation: 'Area available for public recreation 13',
    no14_area_available_for_public_recreation: 'Area available for public recreation 14',
    no15_area_available_for_public_recreation: 'Area available for public recreation 15',
    no16_area_available_for_public_recreation: 'Area available for public recreation 16',
    no17_area_available_for_public_recreation: 'Area available for public recreation 17',
    no18_area_available_for_public_recreation: 'Area available for public recreation 18',
    no19_area_available_for_public_recreation: 'Area available for public recreation 19',
    no20_area_available_for_public_recreation: 'Area available for public recreation 20',
  },
}
