module.exports = {
  home: 'Inicio',
  sections: {
    about: 'Acerca de FRA',
    contentCheck: 'Contenido / Comprobar',
    externalData: 'Datos externos',
    messageBoard: 'Tablero de mensajes',
    overview: 'Visión general',
    recentActivity: 'Actividad reciente',
    repository: 'Enlaces y Repositorio',
    userManagement: 'Gestionar colaboradores',
    versioning: 'Versionando',
  },
  overview: {
    loadingMap: 'Cargando mapa…',
    withFinancialSupportOf: 'Con el apoyo financiero de',
  },
  milestones: {
    milestones: 'Logros',
    milestone1: 'Reunión mundial de Corresponsales Nacionales',
    milestone2: 'Talleres regionales y subregionales',
    milestone3: 'Plazo de presentación de los informes nacionales',
    milestone4: 'Validación de los informes nacionales finales',
    milestone5: 'Resultados preliminares de FRA 2020',
    milestone6: 'Informe principal de FRA 2020',
    milestone7: 'Análisis de datos y redacción de informes',
    milestone8: 'Lanzamiento de la plataforma de análisis y difusión',
    date1: 'Marzo',
    date2: 'Abril – Septiembre',
    date3: 'Octubre',
    date4: 'Enero – Febrero',
    date5: 'Marzo',
    date6: 'Julio',
    date7: 'Marzo – Diciembre',
    date8: 'Agosto',
  },
  users: {
    users: 'Contactos',
    message: 'Mensaje',
  },
  about: {
    contact: 'Contacto',
    seniorForestryOfficer: 'Oficial Forestal Superior',
    faoForestryDepartment: 'Departamento Forestal de la FAO',
    email: 'Correo electrónico',
    fraProcess:
      'La FAO ha estado monitoreando los bosques del mundo en intervalos de 5 a 10 años desde 1946. Las recientes Evaluaciones de los Recursos Forestales Mundiales (FRA) se llevan a cabo ahora cada 5 años, en un esfuerzo por proporcionar un enfoque conherente para describir los bosques del mundo y cómo estos están cambiando. La evaluación se basa en dos principales fuentes de datos: los informes nacionales desarrollados por Corresponsales Naciones y la teledetección, llevada a cabo por la FAO junto con los coordinadores nacionales y los asociados regionales. El alcance de FRA ha cambiado de forma regular desde que se publicó la primera evaluación en 1948. Dichas evaluaciones constituyen una interesante historia sobre los intereses forestales mundiales, tanto en lo que respecta a su contenido sustancial como a su alcance cambiante.',
    linkFraProcess: 'Más información sobre el proceso de FRA',
    or: 'O',
    selectArea: 'Seleccione un área a continuación para acceder a los datos de FRA',
  },
  recentActivity: {
    noRecentActivityTitle: 'Parece que no hay ninguna actividad reciente',
    noRecentActivityBody:
      'Los cambios realizados en la plataforma aparecerán aquí, para que se pueda poner al día sobre lo que ha estado ocurriendo mientras estaba ausente.',
    getStarted: 'Para empezar',
    actions: {
      added: 'ha añadido',
      addedFile: 'ha añadido {{file}}',
      commented: 'ha comentado',
      deleted: 'ha borrado',
      deletedFile: 'ha borrado {{file}}',
      edited: 'ha editado',
      resolved: 'ha resuelto el asunto',
      status: 'ha cambiado el estado a',
      addUser: 'ha añadido a {{user}} como {{role}}',
      updateUser: 'ha editado a {{user}}',
      removeUser: 'ha eliminado a {{user}}',
      acceptInvitation: 'ha sido incorporado/a como {{role}}',
      addInvitation: 'ha invitado a {{user}} como {{role}}',
      removeInvitation: 'ha eliminado la invitación de {{user}} como {{role}}',
      updateInvitation: 'ha actualizado la invitación de {{user}} como {{role}}',
      updateAssessmentStatus: 'ha cambiado el estado de {{assessment}} a {{status}}',
    },
    fraProcess:
      'La FAO ha estado monitoreando los bosques del mundo en intervalos de 5 a 10 años desde 1946. Las recientes Evaluaciones de los Recursos Forestales Mundiales (FRA) se llevan a cabo cada 5 años, en un esfuerzo por proporcionar un enfoque conherente para describir los bosques del mundo y cómo estos están cambiando. La evaluación se basa en dos principales fuentes de datos: los informes nacionales desarrollados por Corresponsales Naciones y la teledetección, llevada a cabo por la FAO junto con los coordinadores nacionales y los asociados regionales. El alcance de FRA ha cambiado de forma regular desde que se publicó la primera evaluación en 1948. Dichas evaluaciones constituyen una interesante historia sobre los intereses forestales mundiales, tanto en lo que respecta a su contenido sustancial como a su alcance cambiante.',
    linkFraProcess: 'Más información sobre el proceso de FRA',
  },
  links: {
    links: 'Enlaces',
    repository: 'Repositorio',
    uploadFile: 'Cargar un archivo',
    confirmDelete: '¿Borrar {{file}}? Esta acción no puede deshacerse.',
    fileUploaded: 'Archivo cargado correctamente',
    fileDeleted: 'Archivo eliminado correctamente',
    fileOperationFailed: `La operación no se puede realizar porque el archivo se utiliza en:\n{{sectionNames}}`,
  },
  dataExport: {
    downloadData: 'Descargar datos',
  },
}
