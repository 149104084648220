module.exports = {
  activated: 'Activado',
  chooseProfilePicture: 'Elegir foto',
  city: 'Ciudad',
  contactPreference: 'Forma de contacto preferida',
  contactPreferenceMethod: 'Método de contacto',
  contributions: 'Contribuciones',
  countryIso: 'País',
  demoteToUser: '¿Está seguro de que desea eliminar los privilegios de administrador?',
  done: 'Guardar',
  email: 'Correo electrónico',
  institution: 'Institución',
  loginEmail: 'Iniciar sesión',
  mandatoryFields: '* son campos obligatorios',
  mr: 'Sr.',
  mrs: 'Srta.',
  ms: 'Sra.',
  name: 'Nombre',
  organization: 'Organización',
  organizationalUnit: 'Unidad organizativa',
  other: 'Otro/a',
  picture1MbMax: 'La foto de perfil no puede ser superior a 1MB',
  platformChat: 'Plataforma de chat',
  poBox: 'Apartado de correos',
  position: 'Cargo',
  primaryEmail: 'Dirección de correo electrónico principal',
  primaryPhoneNumber: 'Número de teléfono principal',
  professionalTitle: 'Título profesional',
  promoteToAdmin: '¿Está seguro de que desea conceder privilegios de administrador?',
  role: 'Función',
  secondaryEmail: 'Dirección de correo electrónico secundaria',
  secondaryPhoneNumber: 'Número de teléfono secundario',
  signal: 'Señal',
  skype: 'Nombre de usuario en Skype',
  status: 'Estado',
  street: 'Dirección ',
  surname: 'Apellido(s)',
  title: 'Denominación',
  whatsapp: 'Whatsapp',
  zipCode: 'Código postal',
}
