module.exports = {
  classValueNotGreaterThan: `{{name}} should be not greater than {{value}}`,
  classValuesMustBeEqualTo: `{{name}} sum must be equal to {{value}}`,
  columnEmpty: `Column '$t({{columName}})' must have a value`,
  countryReportYearGreaterThanCurrentYear: `Value should be greater or equal than {{minValue}}`,
  emptyField: `This field is empty`,
  forestAreaDoesNotMatchExtentOfForest: `Doesn't match Forest area (1a)`,
  forestAreaExceedsExtentOfForest: `Exceeds Forest area (1a) ({{value}})`,
  forestAreaReportedIsDifferentFromPreviousCycle: `Reported area ({{forestArea2025}}) differs from previously reported ({{forestArea2020}}). Please provide a comment explaining why it is different.`,
  forestSumAreaExceedsExtentOfForest: `The sum of the reported FRA categories exceeds the forest area reported in table 1a`,
  invalidLink: 'Invalid link',
  landAreaExceedsTotalLandArea: `Land area exceeds total land area (1a) ({{value}})`,
  mustBeEqualToForestArea: `Sum of Naturally regenerating forest and Planted forest not equal to the total forest`,
  mustBeEqualToForestExpansion: `Subcategories sum should be equal to Forest Expansion`,
  mustBeEqualToPlantedForest: `Subcategories sum should be equal to Planted Forest`,
  mustBeEqualToPrimaryForest: `Subcategories sum should be equal to Primary Forest`,
  mustBeEqualToPrivateOwnership: `Subcategories sum should be equal to Private Ownership`,
  mustBeEqualToTotalGrowingStock: `The summed value should be equal to the Total Forest`,
  mustBeLessThanPrivateOwnership: `Subcategories sum should be less than Private Ownership`,
  notEmpty: `Value cannot be empty`,
  otherLandExceedsExtentOfForest: `Exceeds Other land area (1a)`,
  remainingLandExceedsExtentOfForest: `Exceeds Remaining land area (1a)`,
  shouldContainAtLeastOneCharacter: `Value should contain at least one character`,
  subCategoryExceedsParent: `Subcategory exceeds parent`,
  sumEqualTo: `The sum of {{categoryLabels}} ({{categoriesSum}}) must be equal to {{maxValue}}`,
  sumNotGreaterThan: `Sum should not exceed {{maxValue}}`,
  sumSubCategoriesExceedParent: `The sum of {{categoryLabels}} ({{categoriesSum}}) exceed {{parentLabel}} ({{parentValue}})`,
  sumSubCategoriesNotEqualToParent: `The sum of {{categoryLabels}} ({{categoriesSum}}) is not equal to {{parentLabel}} ({{parentValue}})`,
  valueCannotExceedMaximumValueReportedForForestArea: `Value cannot exceed the maximum value ({{maxForestArea}}) reported for forest area (1a)`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `The sum of year {{year}} cannot exceed the maximum value ({{maxForestArea}}) reported for forest area (1a)`,
  valueCannotExceedMaximumValueReportedForLandArea: `Value cannot exceed the maximum value ({{maxLandArea}}) reported for land area (1a)`,
  valueEqualToSum: `Total value should be equal to sum of subcategories`,
  valueEqualToSumParent: `The sum of subcategories {{subcategories}} is not equal to {{parentVariable}} {{parentCol}} {{parentTable}} <{{valueRounded}}>`,
  valueMustBePositive: `Value should be greater than zero`,
  valueMustBeYear: `Value should be a valid year`,
  valueNotGreaterThan: `Value should be not greater than {{maxValue}}`,
  valuesAreInconsistent1aOr1b: `Values are inconsistent with Areas reported in tables 1a or 1b`,
  valuesAreInconsistentWithNetChange: `Values are inconsistent with Forest area net change`,
}
