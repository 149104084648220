module.exports = {
  accepted: 'Aceptado',
  add: 'Añadir',
  all: 'Todas',
  apply: 'Aplicar',
  areYouSureYouWantToDelete: '¿Está seguro/a de que desea eliminar este elemento? Esta operación no se puede deshacer.',
  cancel: 'Cancelar',
  clearTable: 'Despejar la tabla',
  column: 'Columna',
  continue: 'Continuar',
  copyToClipboard: 'Copiar al portapapeles',
  countries: 'Países',
  country: 'País',
  dashboard: 'Cuadro de mandos',
  dataExport: 'Exportación de datos',
  delete: 'Borrar',
  done: 'Hecho',
  email: 'Correo electrónico',
  file: 'Archivo',
  filterCountries: 'Filtrar países',
  fraPlatform: 'Plataforma de FRA',
  globalFRA: 'Evaluación de los recursos forestales mundiales',
  hide: `Esconder`,
  issues: 'Problemas',
  label: 'Etiqueta',
  language: 'Idioma',
  lastEdit: 'Última edición',
  link: 'Enlace',
  login: 'Área reservada',
  name: 'Nombre',
  or: 'o',
  other: 'Otros',
  otherSpecifyInComments: 'Otros (especifique en los comentarios)',
  private: 'Privado',
  public: 'Público',
  regions: 'Regiones',
  resetAll: 'Resetear',
  role: 'Función',
  select: 'Seleccionar',
  selectAll: 'Seleccionar todo',
  selectArea: 'Seleccionar área geográfica',
  selectFiles: 'Seleccione los archivos',
  show: `Mostrar`,
  showLess: 'Mostrar menos',
  showMore: 'Mostrar más',
  statisticalFactsheets: 'Fichas estadísticas',
  status: 'Estado',
  submit: 'Enviar',
  submittedForApproval: 'Enviado para aprobación',
  submittedToReview: 'Enviado para revisión',
  tier: 'Nivel',
  tiers: { notSelected: '', high: 'Alto', medium: 'Medio', low: 'Bajo' },
  totalPercentage: 'Porcentaje total',
  trend: 'Tendencia',
  unknown: 'Ninguno/desconocido',
  unselectAll: 'Deseleccionar todo',
  updated: 'Actualizado',
  userGuide: 'Guía del usuario',
  variable: 'FRA Variable',
  view: 'Ver',
  year: 'Año',
}
