module.exports = {
  WO: { listName: 'Global' },
  AF: { listName: 'África' },
  AN: { listName: 'Antarctica' },
  AS: { listName: 'Asia' },
  AT: { listName: 'Atlántida' },
  EU: { listName: 'Europa' },
  NA: { listName: 'América del Norte y Central' },
  OC: { listName: 'Oceanía' },
  SA: { listName: 'América del Sur' },

  ASEAN: { listName: 'ASEAN' },
  FE: { listName: 'FOREST EUROPE' },
  EU27: { listName: 'EU-27' },
  ECOMIFAC: { listName: 'Espace COMIFAC' },
  MP: { listName: 'Montreal Process' },
  UNECE: { listName: 'UNECE' },
  ITTO: { listName: 'International Tropical Timber Organization' },

  ABW: { listName: 'Aruba' },
  AFG: { listName: 'Afganistán' },
  AGO: { listName: 'Angola' },
  AIA: { listName: 'Anguila' },
  ALB: { listName: 'Albania' },
  AND: { listName: 'Andorra' },
  ARE: { listName: 'Emiratos Árabes Unidos' },
  ARG: { listName: 'Argentina' },
  ARM: { listName: 'Armenia' },
  ASM: { listName: 'Samoa Americana' },
  ATG: { listName: 'Antigua y Barbuda' },
  AUS: { listName: 'Australia' },
  AUT: { listName: 'Austria' },
  AZE: { listName: 'Azerbaiyán' },
  BDI: { listName: 'Burundi' },
  BEL: { listName: 'Bélgica' },
  BEN: { listName: 'Benin' },
  BES: { listName: 'Bonaire, Sint Eustatius y Saba' },
  BFA: { listName: 'Burkina Faso' },
  BGD: { listName: 'Bangladesh' },
  BGR: { listName: 'Bulgaria' },
  BHR: { listName: 'Bahrein' },
  BHS: { listName: 'Bahamas' },
  BIH: { listName: 'Bosnia y Herzegovina' },
  BLM: { listName: 'San Bartolomé' },
  BLR: { listName: 'Belarús' },
  BLZ: { listName: 'Belice' },
  BMU: { listName: 'Bermudas' },
  BOL: { listName: 'Bolivia (Estado Plurinacional de)' },
  BRA: { listName: 'Brasil' },
  BRB: { listName: 'Barbados' },
  BRN: { listName: 'Brunei Darussalam' },
  BTN: { listName: 'Bhután' },
  BWA: { listName: 'Botswana' },
  CAF: { listName: 'República Centroafricana' },
  CAN: { listName: 'Canadá' },
  CHE: { listName: 'Suiza' },
  CHL: { listName: 'Chile' },
  CHN: { listName: 'China' },
  CIV: { listName: "Côte d'Ivoire" },
  CMR: { listName: 'Camerún' },
  COD: { listName: 'República Democrática del Congo' },
  COG: { listName: 'Congo' },
  COK: { listName: 'Islas Cook' },
  COL: { listName: 'Colombia' },
  COM: { listName: 'Comoras' },
  CPV: { listName: 'Cabo Verde' },
  CRI: { listName: 'Costa Rica' },
  CUB: { listName: 'Cuba' },
  CUW: { listName: 'Curaçao' },
  CYM: { listName: 'Islas Caimán ' },
  CYP: { listName: 'Chipre' },
  CZE: { listName: 'Chequia' },
  DEU: { listName: 'Alemania' },
  DJI: { listName: 'Djibouti' },
  DMA: { listName: 'Dominica' },
  DNK: { listName: 'Dinamarca' },
  DOM: { listName: 'República Dominicana' },
  DZA: { listName: 'Argelia' },
  ECU: { listName: 'Ecuador' },
  EGY: { listName: 'Egipto' },
  ERI: { listName: 'Eritrea' },
  ESH: { listName: 'Sáhara occidental' },
  ESP: { listName: 'España' },
  EST: { listName: 'Estonia' },
  ETH: { listName: 'Etiopía' },
  FIN: { listName: 'Finlandia' },
  FJI: { listName: 'Fiji' },
  FLK: { listName: 'Islas Malvinas (Falkland Islands)' },
  FRA: { listName: 'Francia' },
  FRO: { listName: 'Islas Feroe' },
  FSM: { listName: 'Micronesia (Estados Federados de)' },
  GAB: { listName: 'Gabón' },
  GBR: { listName: 'Reino Unido de Gran Bretaña e Irlanda del Norte' },
  GEO: { listName: 'Georgia' },
  GGY: { listName: 'Isla de Guernesey' },
  GHA: { listName: 'Ghana' },
  GIB: { listName: 'Gibraltar' },
  GIN: { listName: 'Guinea' },
  GLP: { listName: 'Guadalupe' },
  GMB: { listName: 'Gambia' },
  GNB: { listName: 'Guinea-Bissau' },
  GNQ: { listName: 'Guinea Ecuatorial' },
  GRC: { listName: 'Grecia' },
  GRD: { listName: 'Granada' },
  GRL: { listName: 'Groenlandia' },
  GTM: { listName: 'Guatemala' },
  GUF: { listName: 'Guayana Francesa' },
  GUM: { listName: 'Guam' },
  GUY: { listName: 'Guyana' },
  HND: { listName: 'Honduras' },
  HRV: { listName: 'Croacia' },
  HTI: { listName: 'Haití' },
  HUN: { listName: 'Hungría' },
  IDN: { listName: 'Indonesia' },
  IMN: { listName: 'Isla de Man' },
  IND: { listName: 'India' },
  IRL: { listName: 'Irlanda' },
  IRN: { listName: 'Irán (República Islámica del)' },
  IRQ: { listName: 'Iraq' },
  ISL: { listName: 'Islandia' },
  ISR: { listName: 'Israel' },
  ITA: { listName: 'Italia' },
  JAM: { listName: 'Jamaica' },
  JEY: { listName: 'Jersey' },
  JOR: { listName: 'Jordania' },
  JPN: { listName: 'Japón' },
  KAZ: { listName: 'Kazajstán' },
  KEN: { listName: 'Kenya' },
  KGZ: { listName: 'Kirguistán' },
  KHM: { listName: 'Camboya' },
  KIR: { listName: 'Kiribati' },
  KNA: { listName: 'Saint Kitts y Nevis' },
  KOR: { listName: 'República de Corea' },
  KWT: { listName: 'Kuwait' },
  LAO: { listName: 'República Democrática Popular Lao' },
  LBN: { listName: 'Líbano' },
  LBR: { listName: 'Liberia' },
  LBY: { listName: 'Libia' },
  LCA: { listName: 'Santa Lucía' },
  LIE: { listName: 'Liechtenstein' },
  LKA: { listName: 'Sri Lanka' },
  LSO: { listName: 'Lesotho' },
  LTU: { listName: 'Lituania' },
  LUX: { listName: 'Luxemburgo' },
  LVA: { listName: 'Letonia' },
  MAF: { listName: 'Saint-Martin (parte francesa)' },
  MAR: { listName: 'Marruecos' },
  MCO: { listName: 'Mónaco' },
  MDA: { listName: 'República de Moldova' },
  MDG: { listName: 'Madagascar' },
  MDV: { listName: 'Maldivas' },
  MEX: { listName: 'México' },
  MHL: { listName: 'Islas Marshall' },
  MKD: { listName: 'República de Macedonia del Norte' },
  MLI: { listName: 'Malí' },
  MLT: { listName: 'Malta' },
  MMR: { listName: 'Myanmar' },
  MNE: { listName: 'Montenegro' },
  MNG: { listName: 'Mongolia' },
  MNP: { listName: 'Islas Marianas septentrionales' },
  MOZ: { listName: 'Mozambique' },
  MRT: { listName: 'Mauritania' },
  MSR: { listName: 'Montserrat' },
  MTQ: { listName: 'Martinica' },
  MUS: { listName: 'Mauricio' },
  MWI: { listName: 'Malawi' },
  MYS: { listName: 'Malasia' },
  MYT: { listName: 'Mayotte' },
  NAM: { listName: 'Namibia' },
  NCL: { listName: 'Nueva Caledonia' },
  NER: { listName: 'Níger' },
  NFK: { listName: 'Isla Norfolk' },
  NGA: { listName: 'Nigeria' },
  NIC: { listName: 'Nicaragua' },
  NIU: { listName: 'Niue' },
  NLD: { listName: 'Países Bajos (Reino de los)' },
  NOR: { listName: 'Noruega' },
  NPL: { listName: 'Nepal' },
  NRU: { listName: 'Nauru' },
  NZL: { listName: 'Nueva Zelandia' },
  OMN: { listName: 'Omán' },
  PAK: { listName: 'Pakistán' },
  PAN: { listName: 'Panamá' },
  PCN: { listName: 'Pitcairn' },
  PER: { listName: 'Perú' },
  PHL: { listName: 'Filipinas' },
  PLW: { listName: 'Palau' },
  PNG: { listName: 'Papua Nueva Guinea' },
  POL: { listName: 'Polonia' },
  PRI: { listName: 'Puerto Rico' },
  PRK: { listName: 'República Popular Democrática de Corea' },
  PRT: { listName: 'Portugal' },
  PRY: { listName: 'Paraguay' },
  PSE: { listName: 'Palestina' },
  PYF: { listName: 'Polinesia Francesa' },
  QAT: { listName: 'Qatar' },
  REU: { listName: 'Reunión' },
  ROU: { listName: 'Rumania' },
  RUS: { listName: 'Federación de Rusia' },
  RWA: { listName: 'Rwanda' },
  SAU: { listName: 'Arabia Saudita' },
  SDN: { listName: 'Sudán' },
  SEN: { listName: 'Senegal' },
  SGP: { listName: 'Singapur' },
  SHN: { listName: 'Santa Elena' },
  SJM: { listName: 'Islas Svalbard y Jan Mayen' },
  SLB: { listName: 'Islas Salomón' },
  SLE: { listName: 'Sierra Leona' },
  SLV: { listName: 'El Salvador' },
  SMR: { listName: 'San Marino' },
  SOM: { listName: 'Somalia' },
  SPM: { listName: 'Saint-Pierre y Miquelon' },
  SRB: { listName: 'Serbia' },
  SSD: { listName: 'Sudán del Sur' },
  STP: { listName: 'Santo Tomé y Príncipe' },
  SUR: { listName: 'Suriname' },
  SVK: { listName: 'Eslovaquia' },
  SVN: { listName: 'Eslovenia' },
  SWE: { listName: 'Suecia' },
  SWZ: { listName: 'Swazilandia' },
  SXM: { listName: 'Sint Maarten (Dutch part)' },
  SYC: { listName: 'Seychelles' },
  SYR: { listName: 'República Árabe Siria' },
  TCA: { listName: 'Islas Turcas y Caicos' },
  TCD: { listName: 'Chad' },
  TGO: { listName: 'Togo' },
  THA: { listName: 'Tailandia' },
  TJK: { listName: 'Tayikistán' },
  TKL: { listName: 'Tokelau' },
  TKM: { listName: 'Turkmenistán' },
  TLS: { listName: 'Timor-Leste' },
  TON: { listName: 'Tonga' },
  TTO: { listName: 'Trinidad y Tabago' },
  TUN: { listName: 'Túnez' },
  TUR: { listName: 'Türkiye' },
  TUV: { listName: 'Tuvalu' },
  TZA: { listName: 'República Unida de Tanzanía' },
  UGA: { listName: 'Uganda' },
  UKR: { listName: 'Ucrania' },
  URY: { listName: 'Uruguay' },
  USA: { listName: 'Estados Unidos de América' },
  UZB: { listName: 'Uzbekistán' },
  VAT: { listName: 'Santa Sede' },
  VCT: { listName: 'San Vicente y las Granadinas' },
  VEN: { listName: 'Venezuela (República Bolivariana de)' },
  VGB: { listName: 'Islas Vírgenes Británicas' },
  VIR: { listName: 'Islas Vírgenes (EE.UU.)' },
  VNM: { listName: 'Viet Nam' },
  VUT: { listName: 'Vanuatu' },
  WLF: { listName: 'Islas Wallis y Futuna' },
  WSM: { listName: 'Samoa' },
  X01: { listName: 'Atlantis 01' },
  X02: { listName: 'Atlantis 02' },
  X03: { listName: 'Atlantis 03' },
  X04: { listName: 'Atlantis 04' },
  X05: { listName: 'Atlantis 05' },
  X06: { listName: 'Atlantis 06' },
  X07: { listName: 'Atlantis 07' },
  X08: { listName: 'Atlantis 08' },
  X09: { listName: 'Atlantis 09' },
  X10: { listName: 'Atlantis 10' },
  X11: { listName: 'Atlantis 11' },
  X12: { listName: 'Atlantis 12' },
  X13: { listName: 'Atlantis 13' },
  X14: { listName: 'Atlantis 14' },
  X15: { listName: 'Atlantis 15' },
  X16: { listName: 'Atlantis 16' },
  X17: { listName: 'Atlantis 17' },
  X18: { listName: 'Atlantis 18' },
  X19: { listName: 'Atlantis 19' },
  X20: { listName: 'Atlantis 20' },
  YEM: { listName: 'Yemen' },
  ZAF: { listName: 'Sudáfrica' },
  ZMB: { listName: 'Zambia' },
  ZWE: { listName: 'Zimbabwe' },
}
