module.exports = {
  accepted: 'Принято',
  add: 'Добавить',
  all: 'Все',
  apply: 'Применить',
  areYouSureYouWantToDelete: 'Вы уверены, что хотите удалить этот элемент? Эта операция не может быть отменена.',
  areYouSureYouWantToDeleteAllTableData: 'Вы уверены, что хотите удалить все данные из таблицы?',
  calculate: 'Рассчитать',
  cancel: 'Отмена',
  categories: 'Категории',
  clearTable: 'Очистить таблицу',
  column: 'Столбец',
  continue: 'Продолжить',
  copyToClipboard: 'Скопировать в буфер обмена',
  countries: 'Страны',
  country: 'Страна',
  custom: 'Настроить',
  dashboard: 'Панель управления',
  dataExport: 'Экспорт данных',
  delete: 'Удалить',
  done: 'Готово',
  email: 'Электронная почта',
  file: 'Файл',
  filterCountries: 'Отфильтровать страны',
  forestCategoriesLabel2025: 'Леса, прочие лесистые земли и остальные земельные площади',
  fraPlatform: 'Платформой ОЛР',
  globalFRA: 'Глобальная оценка лесных ресурсов',
  issues: 'Проблемы',
  label: 'Ярлык',
  language: 'Язык',
  lastEdit: 'Последнее реадактирование',
  link: 'Ссылка',
  load: 'Загрузить',
  loading: 'Загрузка...',
  login: 'Зарезервированная область',
  name: 'Имя',
  nationalClassifications: 'Национальные классификации',
  or: 'Либо',
  other: 'Другое',
  otherSpecifyInComments: 'Другое (указать в комментариях)',
  private: 'Личный доступ',
  public: 'Oбщий доступ',
  regions: 'Регионы',
  resetAll: 'Сбросить все',
  role: 'Роль',
  select: 'Выбрать',
  selectAll: 'Выделить все',
  selectArea: 'Выбрать географический район',
  selectFiles: 'Выбрать файлы',
  showLess: 'Показать меньше',
  showMore: 'Показать больше',
  source: 'Источник',
  sources: 'Источники',
  statisticalFactsheets: 'Статистические бюллетени',
  status: 'Статус',
  submit: 'Направить',
  submittedForApproval: 'Направлено на утверждение',
  submittedToReview: 'Направлено на рассмотрение',
  tier: 'Уровень',
  tiers: { high: 'Высокий', low: 'Низкий', medium: 'Средний', notSelected: '' },
  totalPercentage: 'Общий процент.',
  trend: 'Тренд',
  unknown: 'Отсутствует/неизвестна',
  unselectAll: 'Снять выделение',
  updated: 'Обновлено',
  userGuide: 'Руководство пользователя',
  variable: 'ОЛР Переменная',
  view: 'Просмотреть',
  year: 'Год',
}
