export enum Unit {
  haThousand = 'haThousand',
  haThousandPerYear = 'haThousandPerYear',
  tonnesPerHa = 'tonnesPerHa',
  cubicMeterPerHa = 'cubicMeterPerHa',
  millionTonnes = 'millionTonnes',
  millionsCubicMeterOverBark = 'millionsCubicMeterOverBark',
  thousandCubicMeter = 'thousandCubicMeter',
  thousandCubicMeterOverBark = 'thousandCubicMeterOverBark',
  thousandCubicMeterRWE = 'thousandCubicMeterRWE',
  thousandPersons = 'thousandPersons',
  fte1000 = 'fte1000',
  numberOfStudents = 'numberOfStudents',
  absoluteNumber = 'absoluteNumber',
  annualNumberOfVisitsMillion = 'annualNumberOfVisitsMillion',
  millionNationalCurrency = 'millionNationalCurrency',
  facilityLengthIn1000Km = 'facilityLengthIn1000Km',
}
