module.exports = {
  extent: {
    title: 'Extensión',
    forest_area: 'Bosque (1000 ha)',
    other_wooded_land: 'Otras tierras boscosas (1000 ha)',
    primary_forest_percent: 'Bosque primario (% de bosque)',
    protected_forest_percent: 'Bosque dentro de áreas protegidas (% de bosques)',
    management_plan_percent: 'Área de bosque con planes de gestión (% de bosque)',
    certified_area: 'Superficie forestal certificada (1000 ha)',
  },
  periodicChangeRate: {
    title: 'Tasas de variación periódica (tasas anuales)',
    forest_area_annual_net_change: 'Variación neta anual del área de bosque (1000 hectáreas)',
    forest_area_annual_net_change_rate: 'Tasa de cambio neto anual del área de bosque (%)',
    other_wooded_land_annual_net_change: 'Variación neta anual del área de otras tierras boscosas(1000 ha)',
    other_wooded_land_annual_net_change_rate: 'Tasa de variación neta anual del área de otras tierras boscosas (%)',
    primary_forest_annual_net_change: 'Cambio neto anual de bosque primario (1000 ha)',
    primary_forest_annual_net_change_rate: 'Tasa de cambio del área de bosque primario (%)',
    natural_forest_area_annual_net_change: 'Cambio neto anual de bosque con procesos de regeneración natural',
    natural_forest_area_annual_net_change_rate: 'Tasa de cambio de bosque con procesos de regeneración natural (%)',
    planted_forest_annual_net_change: 'Variación neta anual de bosque plantado',
    planted_forest_annual_net_change_rate: 'Tasa de cambio de bosque plantado (%)',
  },
  forestGrowingStockBiomassCarbon: {
    title: 'Existencias forestales en formación, biomasa y carbono',
    forest: 'Existencias forestales en formación (m3/ha)',
    forest_above_ground: 'Biomasa aérea (t/ha)',
    forest_below_ground: 'Biomasa subterránea (t/ha)',
    forest_deadwood: 'Madera muerta (t/ha)',
    carbon_forest_above_ground: 'C de la biomasa aérea (t/ha)',
    carbon_forest_below_ground: 'C de la biomasa subterránea (t/ha)',
    carbon_forest_deadwood: 'C de madera muerta (t/ha)',
    carbon_forest_litter: 'C de la hojarasca (t/ha)',
    carbon_forest_soil: 'C del suelo (t/ha)',
    above_ground_biomass_growing_stock_ratio: 'Relación (biomasa aérea) / existencias en formación (t/m3)',
    root_shoot_ratio: 'Ratio raíz-tallo',
    carbon_biomass_deadwood_ratio: 'Ratio (madera muerta / biomasa viva)',
    carbon_biomass_above_ground_ratio: 'Ratio (carbono/biomasa) de la biomasa aérea',
    carbon_biomass_below_ground_ratio: 'Ratio (carbono/biomasa) de la biomasa subterránea',
    dead_living_mass_ratio: 'Ratio (carbono/biomasa) de la madera muerta',
  },
  primaryDesignatedManagementObjective: {
    title: 'Objetivo principal de gestión designado',
  },
  totalAreaDesignatedManagementObjective: 'Área total con objetivo de gestión designado',
  forestOwnership: {
    title: 'Propiedad',
  },
  holderOfManagementRights: {
    title: 'Derechos de gestión de bosques públicos',
  },
  disturbances: {
    title: 'Perturbaciones',
  },
}
