module.exports = {
  classValueNotGreaterThan: `{{name}} ne doit pas être supérieur à {{value}}`,
  classValuesMustBeEqualTo: `{{name}} la somme doit être égale à {{value}}`,
  columnEmpty: `La colonne "$t({{columName}})" doit avoir une valeur`,
  countryReportYearGreaterThanCurrentYear: `La valeur doit être supérieure ou égale à {{minValue}}`,
  emptyField: `Ce champ est vide`,
  forestAreaDoesNotMatchExtentOfForest: `Ne correspond pas là la Superficie de forêt (1a)`,
  forestAreaExceedsExtentOfForest: `Ne correspond pas à la Superficie de forêt (1a)`,
  forestAreaReportedIsDifferentFromPreviousCycle: `La superficie indiquée ({{forestArea2025}}) diffère de celle précédemment déclarée ({{forestArea2020}}). Veuillez fournir un commentaire expliquant les raisons de cette différence`,
  forestSumAreaExceedsExtentOfForest: `La somme des valeurs des catégories de FRA renseignées dépasse la valeur de la superficie de forêt totale indiquée dans le tableau 1a`,
  invalidLink: `Lien non valide`,
  landAreaExceedsTotalLandArea: `La superficie des terres dépasse la superficie totale des terres (1a) ({{value}})`,
  mustBeEqualToForestArea: `La somme des valeurs de la Forêt naturellement régénérée et de la Forêt Plantée n'est pas égale à la valeur totale de la Forêt`,
  mustBeEqualToForestExpansion: `La somme des valeurs des sous-catégories doit être égale à la valeur de l'Expansion de la forêt`,
  mustBeEqualToPlantedForest: `La somme des valeurs des sous-catégories doit être égale à la valeur de la Forêt Plantée`,
  mustBeEqualToPrimaryForest: `La somme des valeurs des sous-catégories doit être égale à la valeur de la Forêt Primaire`,
  mustBeEqualToPrivateOwnership: `La somme des valeurs des sous-catégories doit être égale à la valeur de la Propriété privée`,
  mustBeEqualToTotalGrowingStock: `La valeur obtenue doit être égale à la valeur totale de la Forêt.`,
  mustBeLessThanPrivateOwnership: `La somme des  valeurs des sous-catégories doit être inférieure à la valeur de la Propriété privée`,
  notEmpty: `La valeur ne peut pas être vide`,
  otherLandExceedsExtentOfForest: `Supérieur à la superficie des autres terres (1a)`,
  remainingLandExceedsExtentOfForest: `Dépasse la superficie des terres restantes (1a)`,
  shouldContainAtLeastOneCharacter: `La valeur doit contenir au moins un caractère`,
  subCategoryExceedsParent: `La valeur de la sous-catégorie dépasse celle de la catégorie`,
  sumEqualTo: 'La somme de {{categoryLabels}} ({{categoriesSum}}) doit être égale à {{maxValue}}',
  sumNotGreaterThan: `La somme ne doit pas dépasser {{maxValue}}`,
  sumSubCategoriesExceedParent: `La somme des {{categoryLabels}} dépasse {{parentLabel}} ({{parentValue}})`,
  sumSubCategoriesNotEqualToParent: `La somme de {{categoryLabels}} ({{categoriesSum}}) n'est pas égale à {{parentLabel}} ({{parentValue}})`,
  valueCannotExceedMaximumValueReportedForForestArea: `La valeur ne peut pas dépasser la valeur maximale ({{maxForestArea}}) de la superficie de forêt indiquée en (1a)`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `La somme de l'année {{year}} ne peut pas dépasser la valeur maximale ({{maxForestArea}}) de la superficie de forêt indiquée en (1a)`,
  valueCannotExceedMaximumValueReportedForLandArea: `La valeur ne peut pas dépasser la valeur maximale ({{maxLandArea}}) de la superficie des terres (1a)`,
  valueEqualToSum: `La valeur totale doit être égale à la somme des valeurs des sous-catégories`,
  valueEqualToSumParent: `La somme des valeurs des sous-catégories {{subcategories}} n'est pas égale à {{parentVariable}} {{parentCol}} {{parentTable}} <{{valueRounded}}>`,
  valueMustBePositive: `La valeur doit être supérieure à zéro`,
  valueMustBeYear: `La valeur doit être une année valide`,
  valueNotGreaterThan: `La valeur ne doit pas être supérieure à {{maxValue}}`,
  valuesAreInconsistent1aOr1b: `Les valeurs ne sont pas cohérentes avec celles des superficies indiquées dans les tableaux 1a ou 1b`,
  valuesAreInconsistentWithNetChange: `Les valeurs ne sont pas cohérentes avec celle du changement net de la superficie de forêt`,
}
