module.exports = {
  classValueNotGreaterThan: `{{name}} должно быть не больше, чем {{value}}`,
  classValuesMustBeEqualTo: `{{name}} сумма должна быть равна {{value}}`,
  columnEmpty: `Столбец '$ t ({{coluname}})' должен иметь значение`,
  countryReportYearGreaterThanCurrentYear: `Значение должно быть больше чем либо равно {{minvalue}}`,
  emptyField: `Это поле пусто`,
  forestAreaDoesNotMatchExtentOfForest: `Не соответствует площади лесов (1А)`,
  forestAreaExceedsExtentOfForest: `Превышает "Площадь лесов" (1a)`,
  forestAreaReportedIsDifferentFromPreviousCycle: `Представленная площадь ({{forestarea2025}}) отличается от ранее представленной ({{forestarea2020}}). Пожалуйста, оставьте комментарий, объясняющий, почему она отличается.`,
  forestSumAreaExceedsExtentOfForest: `Сумма представленных категорий FRA превышает лесной площади, о которой сообщается в таблице 1а`,
  invalidLink: `Недействительная ссылка`,
  landAreaExceedsTotalLandArea: `Площадь земель превышает общую площадь земель (1a) ({{value}})`,
  mustBeEqualToForestArea: `Сумма естественно возобновляемых лесов и лесных культур не равна общей площади лесов`,
  mustBeEqualToForestExpansion: `Сумма подкатегорий должна быть равна расширению лесов`,
  mustBeEqualToPlantedForest: `Сумма подкатегорий должна быть равна лесным культурам`,
  mustBeEqualToPrimaryForest: `Сумма подкатегорий должна быть равна первичным лесам`,
  mustBeEqualToPrivateOwnership: `Сумма подкатегорий должна быть равна частной собственности`,
  mustBeEqualToTotalGrowingStock: `Суммированное значение должно быть равным общей площади лесов`,
  mustBeLessThanPrivateOwnership: `Сумма подкатегорий должна быть меньше, чем частная собственность`,
  notEmpty: `Значение не может быть пустым`,
  otherLandExceedsExtentOfForest: `Превышает "Другие земельные площади" (1a)`,
  remainingLandExceedsExtentOfForest: `Превышает остальные земельные площади (1А)`,
  shouldContainAtLeastOneCharacter: `Значение должно содержать хотя бы один символ`,
  subCategoryExceedsParent: `Подкатегория превышает родительскую категорию`,
  sumEqualTo: 'Сумма {{categoryLabels}} ({{categoriesSum}}) должна быть равна {{maxValue}}',
  sumNotGreaterThan: `Сумма не должна превышать {{maxValue}}`,
  sumSubCategoriesExceedParent: `Сумма {{CategoryLabels}} превышает {{parentLabel}} ({{parentValue}})`,
  sumSubCategoriesNotEqualToParent: `Сумма {{categoryLabels}} ({{categoriesSum}}) не равна {{parentLabel}} ({{parentValue}})`,
  valueCannotExceedMaximumValueReportedForForestArea: `Значение не может превышать максимальное значение ({{maxforestarea}}), представляемое для площади лесов (1a)`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `Сумма за год {{year}} не может превышать максимальное значение ({{maxforestarea}}), представляемое для площади лесов (1a)`,
  valueCannotExceedMaximumValueReportedForLandArea: `Значение не может превышать максимальное значение ({{maxlandarea}}), представляемое для площади земель (1a)`,
  valueEqualToSum: `Общее значение должно быть равным сумме подкатегорий`,
  valueEqualToSumParent: `Сумма подкатегорий {{subcategories}} не равна {{parentvariable}} {{parentcol}} {{parenttable}} <{{valuErounded}}>>>>>>>>>>>>`,
  valueMustBePositive: `Значение должно быть больше нуля`,
  valueMustBeYear: `Значение должно быть действительным годом`,
  valueNotGreaterThan: `Значение должно быть не больше, чем {{maxValue}}`,
  valuesAreInconsistent1aOr1b: `Значения не соответствуют площадям, представленным в таблицах 1a или 1b`,
  valuesAreInconsistentWithNetChange: `Значения не соответствуют чистому изменению площади лесов`,
}
