module.exports = {
  WO: { listName: 'Глобальный' },
  AF: { listName: 'Африка' },
  AN: { listName: 'Antarctica' },
  AS: { listName: 'Азия' },
  AT: { listName: 'Атлантида' },
  EU: { listName: 'Европа' },
  NA: { listName: 'Северная и Центральная Америка' },
  OC: { listName: 'Океания' },
  SA: { listName: 'Южная Америка' },

  ASEAN: { listName: 'ASEAN' },
  FE: { listName: 'ЛЕСА ЕВРОПЫ' },
  EU27: { listName: 'ЕС-27' },
  ECOMIFAC: { listName: 'Espace КОМИФАК' },
  MP: { listName: 'Монреальский процесс' },
  UNECE: { listName: 'ЕЭК ООН' },
  ITTO: { listName: 'Международная организация по тропической древесине' },

  ABW: { listName: 'Аруба' },
  AFG: { listName: 'Афганистан' },
  AGO: { listName: 'Ангола' },
  AIA: { listName: 'Ангилья' },
  ALB: { listName: 'Албания' },
  AND: { listName: 'Андорра' },
  ARE: { listName: 'Объединенные Арабские Эмираты' },
  ARG: { listName: 'Аргентина' },
  ARM: { listName: 'Армения' },
  ASM: { listName: 'Американское Самоа' },
  ATG: { listName: 'Антигуа и Барбуда' },
  AUS: { listName: 'Австралия' },
  AUT: { listName: 'Австрия' },
  AZE: { listName: 'Азербайджан' },
  BDI: { listName: 'Бурунди' },
  BEL: { listName: 'Бельгия' },
  BEN: { listName: 'Бенин' },
  BES: { listName: 'Бонайре, Синт-Эстатиус и Саба' },
  BFA: { listName: 'Буркина-Фасо' },
  BGD: { listName: 'Бангладеш' },
  BGR: { listName: 'Болгария' },
  BHR: { listName: 'Бахрейн' },
  BHS: { listName: 'Багамские Острова' },
  BIH: { listName: 'Босния и Герцеговина' },
  BLM: { listName: 'Сен-Бартельми' },
  BLR: { listName: 'Беларусь' },
  BLZ: { listName: 'Белиз' },
  BMU: { listName: 'Бермудские острова' },
  BOL: { listName: 'Боливия (Многонациональное Государство)' },
  BRA: { listName: 'Бразилия' },
  BRB: { listName: 'Барбадос' },
  BRN: { listName: 'Бруней-Даруссалам' },
  BTN: { listName: 'Бутан' },
  BWA: { listName: 'Ботсвана' },
  CAF: { listName: 'Центральноафриканская Республика' },
  CAN: { listName: 'Канада' },
  CHE: { listName: 'Швейцария' },
  CHL: { listName: 'Чили' },
  CHN: { listName: 'Китай' },
  CIV: { listName: 'Кот-д`Ивуар' },
  CMR: { listName: 'Камерун' },
  COD: { listName: 'Демократическая Республика Конго' },
  COG: { listName: 'Конго' },
  COK: { listName: 'Острова Кука' },
  COL: { listName: 'Колумбия' },
  COM: { listName: 'Коморские Острова' },
  CPV: { listName: 'Кабо-Верде' },
  CRI: { listName: 'Коста-Рика' },
  CUB: { listName: 'Куба' },
  CUW: { listName: 'Кюрасао' },
  CYM: { listName: 'Каймановы острова' },
  CYP: { listName: 'Кипр' },
  CZE: { listName: 'Чехия' },
  DEU: { listName: 'Германия' },
  DJI: { listName: 'Джибути' },
  DMA: { listName: 'Доминика' },
  DNK: { listName: 'Дания' },
  DOM: { listName: 'Доминиканская Республика' },
  DZA: { listName: 'Алжир' },
  ECU: { listName: 'Эквадор' },
  EGY: { listName: 'Египет' },
  ERI: { listName: 'Эритрея' },
  ESH: { listName: 'Западная Сахара' },
  ESP: { listName: 'Испания' },
  EST: { listName: 'Эстония' },
  ETH: { listName: 'Эфиопия' },
  FIN: { listName: 'Финляндия' },
  FJI: { listName: 'Фиджи' },
  FLK: { listName: 'Фолклендские (Мальвинские) острова' },
  FRA: { listName: 'Франция' },
  FRO: { listName: 'Фарерские Острова' },
  FSM: { listName: 'Микронезия (Федеративные Штаты)' },
  GAB: { listName: 'Габон' },
  GBR: { listName: 'Соединенное Королевство Великобритании и Северной Ирландии' },
  GEO: { listName: 'Грузия' },
  GGY: { listName: 'Гернси' },
  GHA: { listName: 'Гана' },
  GIB: { listName: 'Гибралтар' },
  GIN: { listName: 'Гвинея' },
  GLP: { listName: 'Гваделупа' },
  GMB: { listName: 'Гамбия' },
  GNB: { listName: 'Гвинея-Бисау' },
  GNQ: { listName: 'Экваториальная Гвинея' },
  GRC: { listName: 'Греция' },
  GRD: { listName: 'Гренада' },
  GRL: { listName: 'Гренландия' },
  GTM: { listName: 'Гватемала' },
  GUF: { listName: 'Французская Гвиана' },
  GUM: { listName: 'Гуам' },
  GUY: { listName: 'Гайана' },
  HND: { listName: 'Гондурас' },
  HRV: { listName: 'Хорватия' },
  HTI: { listName: 'Гаити' },
  HUN: { listName: 'Венгрия' },
  IDN: { listName: 'Индонезия' },
  IMN: { listName: 'остров Мэн' },
  IND: { listName: 'Индия' },
  IRL: { listName: 'Ирландия' },
  IRN: { listName: 'Иран (Исламская Республика)' },
  IRQ: { listName: 'Ирак' },
  ISL: { listName: 'Исландия' },
  ISR: { listName: 'Израиль' },
  ITA: { listName: 'Италия' },
  JAM: { listName: 'Ямайка' },
  JEY: { listName: 'Джерси' },
  JOR: { listName: 'Иордания' },
  JPN: { listName: 'Япония' },
  KAZ: { listName: 'Казахстан' },
  KEN: { listName: 'Кения' },
  KGZ: { listName: 'Кыргызстан' },
  KHM: { listName: 'Камбоджа' },
  KIR: { listName: 'Кирибати' },
  KNA: { listName: 'Сент-Китс и Невис' },
  KOR: { listName: 'Республика Корея' },
  KWT: { listName: 'Кувейт' },
  LAO: { listName: 'Лаосская Народно-Демократическая Республика' },
  LBN: { listName: 'Ливан' },
  LBR: { listName: 'Либерия' },
  LBY: { listName: 'Ливия' },
  LCA: { listName: 'Сент-Люсия' },
  LIE: { listName: 'Лихтенштейн' },
  LKA: { listName: 'Шри-Ланка' },
  LSO: { listName: 'Лесото' },
  LTU: { listName: 'Литва' },
  LUX: { listName: 'Люксембург' },
  LVA: { listName: 'Латвия' },
  MAF: { listName: 'Сен-Мартен (французская часть)' },
  MAR: { listName: 'Марокко' },
  MCO: { listName: 'Монако' },
  MDA: { listName: 'Республика Молдова' },
  MDG: { listName: 'Мадагаскар' },
  MDV: { listName: 'Мальдивы' },
  MEX: { listName: 'Мексика' },
  MHL: { listName: 'Маршалловы Острова' },
  MKD: { listName: 'Республика Северная Македония' },
  MLI: { listName: 'Мали' },
  MLT: { listName: 'Мальта' },
  MMR: { listName: 'Мьянма' },
  MNE: { listName: 'Черногория' },
  MNG: { listName: 'Монголия' },
  MNP: { listName: 'Северные Марианские острова' },
  MOZ: { listName: 'Мозамбик' },
  MRT: { listName: 'Мавритания' },
  MSR: { listName: 'Монтсеррат' },
  MTQ: { listName: 'Мартиника' },
  MUS: { listName: 'Маврикий' },
  MWI: { listName: 'Малави' },
  MYS: { listName: 'Малайзия' },
  MYT: { listName: 'Майотта' },
  NAM: { listName: 'Намибия' },
  NCL: { listName: 'Новая Каледония' },
  NER: { listName: 'Нигер' },
  NFK: { listName: 'остров Норфолк' },
  NGA: { listName: 'Нигерия' },
  NIC: { listName: 'Никарагуа' },
  NIU: { listName: 'Ниуэ' },
  NLD: { listName: 'Нидерланды (Королевство)' },
  NOR: { listName: 'Норвегия' },
  NPL: { listName: 'Непал' },
  NRU: { listName: 'Науру' },
  NZL: { listName: 'Новая Зеландия' },
  OMN: { listName: 'Оман' },
  PAK: { listName: 'Пакистан' },
  PAN: { listName: 'Панама' },
  PCN: { listName: 'Питкэрн' },
  PER: { listName: 'Перу' },
  PHL: { listName: 'Филиппины' },
  PLW: { listName: 'Палау' },
  PNG: { listName: 'Папуа-Новая Гвинея' },
  POL: { listName: 'Польша' },
  PRI: { listName: 'Пуэрто-Рико' },
  PRK: { listName: 'Корейская Народно-Демократическая Республика' },
  PRT: { listName: 'Португалия' },
  PRY: { listName: 'Парагвай' },
  PSE: { listName: 'Палестина' },
  PYF: { listName: 'Французская Полинезия' },
  QAT: { listName: 'Катар' },
  REU: { listName: 'Реюньон' },
  ROU: { listName: 'Румыния' },
  RUS: { listName: 'Российская Федерация' },
  RWA: { listName: 'Руанда' },
  SAU: { listName: 'Саудовская Аравия' },
  SDN: { listName: 'Судан' },
  SEN: { listName: 'Сенегал' },
  SGP: { listName: 'Сингапур' },
  SHN: { listName: 'Остров Святой Елены' },
  SJM: { listName: 'острова Свальбард и Ян-Майен' },
  SLB: { listName: 'Соломоновы Острова' },
  SLE: { listName: 'Сьерра-Леоне' },
  SLV: { listName: 'Сальвадор' },
  SMR: { listName: 'Сан-Марино' },
  SOM: { listName: 'Сомали' },
  SPM: { listName: 'Сен-Пьер и Микелон' },
  SRB: { listName: 'Сербия' },
  SSD: { listName: 'Южный Судан' },
  STP: { listName: 'Сан-Томе и Принсипи' },
  SUR: { listName: 'Суринам' },
  SVK: { listName: 'Словакия' },
  SVN: { listName: 'Словения' },
  SWE: { listName: 'Швеция' },
  SWZ: { listName: 'Эсватини' },
  SXM: { listName: 'Сен-Мартен (голландская часть)' },
  SYC: { listName: 'Сейшельские Острова' },
  SYR: { listName: 'Сирийская Арабская Республика' },
  TCA: { listName: 'острова Тёркс и Кайкос' },
  TCD: { listName: 'Чад' },
  TGO: { listName: 'Того' },
  THA: { listName: 'Таиланд' },
  TJK: { listName: 'Таджикистан' },
  TKL: { listName: 'Токелау' },
  TKM: { listName: 'Туркменистан' },
  TLS: { listName: 'Тимор-Лешти' },
  TON: { listName: 'Тонга' },
  TTO: { listName: 'Тринидад и Тобаго' },
  TUN: { listName: 'Тунис' },
  TUR: { listName: 'Турция' },
  TUV: { listName: 'Тувалу' },
  TZA: { listName: 'Объединенная Республика Танзания' },
  UGA: { listName: 'Уганда' },
  UKR: { listName: 'Украина' },
  URY: { listName: 'Уругвай' },
  USA: { listName: 'Соединенные Штаты Америки' },
  UZB: { listName: 'Узбекистан' },
  VAT: { listName: 'Святой Престол' },
  VCT: { listName: 'Сент-Винсент и Гренадины' },
  VEN: { listName: 'Венесуэла (Боливарианская Республика)' },
  VGB: { listName: 'Британские Виргинские острова' },
  VIR: { listName: 'Виргинские острова США' },
  VNM: { listName: 'Вьетнам' },
  VUT: { listName: 'Вануату' },
  WLF: { listName: 'острова Уоллис и Футуна' },
  WSM: { listName: 'Самоа' },
  X01: { listName: 'Atlantis 01' },
  X02: { listName: 'Atlantis 02' },
  X03: { listName: 'Atlantis 03' },
  X04: { listName: 'Atlantis 04' },
  X05: { listName: 'Atlantis 05' },
  X06: { listName: 'Atlantis 06' },
  X07: { listName: 'Atlantis 07' },
  X08: { listName: 'Atlantis 08' },
  X09: { listName: 'Atlantis 09' },
  X10: { listName: 'Atlantis 10' },
  X11: { listName: 'Atlantis 11' },
  X12: { listName: 'Atlantis 12' },
  X13: { listName: 'Atlantis 13' },
  X14: { listName: 'Atlantis 14' },
  X15: { listName: 'Atlantis 15' },
  X16: { listName: 'Atlantis 16' },
  X17: { listName: 'Atlantis 17' },
  X18: { listName: 'Atlantis 18' },
  X19: { listName: 'Atlantis 19' },
  X20: { listName: 'Atlantis 20' },
  YEM: { listName: 'Йемен' },
  ZAF: { listName: 'Южная Африка' },
  ZMB: { listName: 'Замбия' },
  ZWE: { listName: 'Зимбабве' },
}
