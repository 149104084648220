module.exports = {
  WO: { listName: 'العالم' },
  AF: { listName: 'أفريقيا' },
  AN: { listName: 'القطب الجنوبي' },
  AS: { listName: 'آسيا' },
  AT: { listName: 'أطلانطس' },
  EU: { listName: 'أوروبا' },
  NA: { listName: 'أمريكا الشمالية والوسطى' },
  OC: { listName: 'أوقيانوسيا' },
  SA: { listName: 'أمريكا الجنوبية' },

  ASEAN: { listName: 'ASEAN' },
  FE: { listName: 'المؤتمر الوزاري لحماية الغابات في أوروبا' },
  EU27: { listName: 'الاتحاد الأوروبي - 27' },
  ECOMIFAC: { listName: 'اللجنة المعنية بغابات أفريقيا الوسطى' },
  MP: { listName: 'عملية مونتريال' },
  UNECE: { listName: 'لجنة الأمم المتحدة الاقتصادية لأوروبا' },
  ITTO: { listName: 'المنظمة الدولية للأخشاب الاستوائية' },

  ABW: { listName: 'أروبا' },
  AFG: { listName: 'أفغانستان' },
  AGO: { listName: 'أنجولا' },
  AIA: { listName: 'أنجويلا' },
  ALB: { listName: 'ألبانيا' },
  AND: { listName: 'أندورا' },
  ARE: { listName: 'الإمارات العربية المتحدة' },
  ARG: { listName: 'الأرجنتين' },
  ARM: { listName: 'أرمينيا' },
  ASM: { listName: 'ساموا الأمريكية' },
  ATG: { listName: 'أنتيغوا وبروبودا' },
  AUS: { listName: 'أستراليا' },
  AUT: { listName: 'النمسا' },
  AZE: { listName: 'أذربيجان' },
  BDI: { listName: 'بروندي' },
  BEL: { listName: 'بلجيكا' },
  BEN: { listName: 'بنن' },
  BES: { listName: 'بونير وسان بوستاتيوس وسابا' },
  BFA: { listName: 'بوركينا فاسو' },
  BGD: { listName: 'بنغلاديش' },
  BGR: { listName: 'بلغاريا' },
  BHR: { listName: 'البحرين' },
  BHS: { listName: 'جزر البهاما' },
  BIH: { listName: 'البوسنة والهرسك' },
  BLM: { listName: 'سان بارثيليمي' },
  BLR: { listName: 'بيلاروس' },
  BLZ: { listName: 'بليز' },
  BMU: { listName: 'برمودا' },
  BOL: { listName: 'دولة بوليفيا المتعددة القوميات' },
  BRA: { listName: 'البرازيل' },
  BRB: { listName: 'بربادوس' },
  BRN: { listName: 'بروني دار السلام' },
  BTN: { listName: 'بوتان' },
  BWA: { listName: 'بوتسوانا' },
  CAF: { listName: 'جمهورية أفريقيا الوسطى' },
  CAN: { listName: 'كندا' },
  CHE: { listName: 'سويسرا' },
  CHL: { listName: 'شيلي' },
  CHN: { listName: 'الصين' },
  CIV: { listName: 'كوت دي فوار' },
  CMR: { listName: 'الكاميرون' },
  COD: { listName: 'جمهورية الكونغو الديمقراطية' },
  COG: { listName: 'الكونغو' },
  COK: { listName: 'جزر كوك' },
  COL: { listName: 'كولومبيا' },
  COM: { listName: 'جزر القمر' },
  CPV: { listName: 'كابو فيردي' },
  CRI: { listName: 'كوستا ريكا' },
  CUB: { listName: 'كوبا' },
  CUW: { listName: 'جزر كوراساو' },
  CYM: { listName: 'جزر كايمان' },
  CYP: { listName: 'قبرص' },
  CZE: { listName: 'تشيكيا' },
  DEU: { listName: 'ألمانيا' },
  DJI: { listName: 'جيبوتي' },
  DMA: { listName: 'دومينيكا' },
  DNK: { listName: 'الدانمرك' },
  DOM: { listName: 'جمهورية الدومينيكان' },
  DZA: { listName: 'الجزائر' },
  ECU: { listName: 'إكوادور' },
  EGY: { listName: 'مصر' },
  ERI: { listName: 'أريتريا' },
  ESH: { listName: 'الصحراء الغربية' },
  ESP: { listName: 'اسبانيا' },
  EST: { listName: 'استونيا' },
  ETH: { listName: 'إثيوبيا' },
  FIN: { listName: 'فنلندا' },
  FJI: { listName: 'فيجي' },
  FLK: { listName: 'جزر فوكلاند (مالفيناس)' },
  FRA: { listName: 'فرنسا' },
  FRO: { listName: 'جزر فارو' },
  FSM: { listName: 'ولايات ميكرونيزيا الموحدة' },
  GAB: { listName: 'الغابون' },
  GBR: { listName: 'المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية' },
  GEO: { listName: 'جورجيا' },
  GGY: { listName: 'جزيرة غيرنسي' },
  GHA: { listName: 'غانا' },
  GIB: { listName: 'جبل طارق' },
  GIN: { listName: 'غينيا' },
  GLP: { listName: 'غوادلوب' },
  GMB: { listName: 'غامبيا' },
  GNB: { listName: 'غينيا - بيساو' },
  GNQ: { listName: 'غينيا الاستوائية' },
  GRC: { listName: 'اليونان' },
  GRD: { listName: 'غرينادا' },
  GRL: { listName: 'غرينلاند' },
  GTM: { listName: 'غواتيمالا' },
  GUF: { listName: 'غيانا الفرنسية' },
  GUM: { listName: 'مجموعة بلدان جورجيا وأوكرانيا وأذربيجان ومولدوفا' },
  GUY: { listName: 'غيانا' },
  HND: { listName: 'هندوراس' },
  HRV: { listName: 'كرواتيا' },
  HTI: { listName: 'هايتي' },
  HUN: { listName: 'هنغاريا' },
  IDN: { listName: 'أندونيسيا' },
  IMN: { listName: 'جزيرة مان' },
  IND: { listName: 'الهند' },
  IRL: { listName: 'أيرلندا' },
  IRN: { listName: 'جمهورية إيران الإسلامية' },
  IRQ: { listName: 'العراق' },
  ISL: { listName: 'أيسلندا' },
  ISR: { listName: 'إسرائيل' },
  ITA: { listName: 'إيطاليا' },
  JAM: { listName: 'جاميكا' },
  JEY: { listName: 'جيرسي' },
  JOR: { listName: 'الأردن' },
  JPN: { listName: 'اليابان' },
  KAZ: { listName: 'كازاخستان' },
  KEN: { listName: 'كينيا' },
  KGZ: { listName: 'قيرغيزستان' },
  KHM: { listName: 'كامبوديا' },
  KIR: { listName: 'كيريباتي' },
  KNA: { listName: 'سانت كيتس ونفيس' },
  KOR: { listName: 'جمهورية كوريا' },
  KWT: { listName: 'الكويت' },
  LAO: { listName: 'جمهورية لاو الديمقراطية الشعبية' },
  LBN: { listName: 'لبنان' },
  LBR: { listName: 'ليبيريا' },
  LBY: { listName: 'ليبيا' },
  LCA: { listName: 'سانت لوسيا' },
  LIE: { listName: 'ليختنشتاين' },
  LKA: { listName: 'سري لانكا' },
  LSO: { listName: 'ليسوتو' },
  LTU: { listName: 'ليتوانيا' },
  LUX: { listName: 'لوكسمبورغ' },
  LVA: { listName: 'لاتفيا' },
  MAF: { listName: 'سانت مارتن (الجانب الفرنسي)' },
  MAR: { listName: 'المغرب' },
  MCO: { listName: 'موناكو' },
  MDA: { listName: 'جمهورية مولدوفا' },
  MDG: { listName: 'مدغشقر' },
  MDV: { listName: 'جزر المالديف' },
  MEX: { listName: 'المكسيك' },
  MHL: { listName: 'جزر مارشال' },
  MKD: { listName: 'جمهورية مقدونيا الشمالية' },
  MLI: { listName: 'مالي' },
  MLT: { listName: 'مالطا' },
  MMR: { listName: 'ميانمار' },
  MNE: { listName: 'الجبل الأسود' },
  MNG: { listName: 'منغوليا' },
  MNP: { listName: 'جزر مارينا الشمالية' },
  MOZ: { listName: 'موزامبيق' },
  MRT: { listName: 'موريتانيا' },
  MSR: { listName: 'مونتسيرات' },
  MTQ: { listName: 'المارتينيك' },
  MUS: { listName: 'موريشوس' },
  MWI: { listName: 'ملاوي' },
  MYS: { listName: 'ماليزيا' },
  MYT: { listName: 'مايوت' },
  NAM: { listName: 'ناميبيا' },
  NCL: { listName: 'كاليدونيا الجديدة' },
  NER: { listName: 'النيجر' },
  NFK: { listName: 'جزيرة نورفولك' },
  NGA: { listName: 'نيجيريا' },
  NIC: { listName: 'نيكاراغوا' },
  NIU: { listName: 'نيوي' },
  NLD: { listName: 'هولندا (مملكة)' },
  NOR: { listName: 'النرويج' },
  NPL: { listName: 'نيبال' },
  NRU: { listName: 'ناورو' },
  NZL: { listName: 'نيوزيلندا' },
  OMN: { listName: 'عمان' },
  PAK: { listName: 'باكستان' },
  PAN: { listName: 'باناما' },
  PCN: { listName: 'بيتكيرن' },
  PER: { listName: 'بيرو' },
  PHL: { listName: 'الفلبين' },
  PLW: { listName: 'بالاو' },
  PNG: { listName: 'بابوا غينيا الجديدة' },
  POL: { listName: 'بولندا' },
  PRI: { listName: 'بورتوريكو' },
  PRK: { listName: 'جمهورية كوريا الديمقراطية الشعبية' },
  PRT: { listName: 'البرتغال' },
  PRY: { listName: 'باراغواي' },
  PSE: { listName: 'فلسطين' },
  PYF: { listName: 'بولينزيا الفرنسية' },
  QAT: { listName: 'قطر' },
  REU: { listName: 'ريونيون' },
  ROU: { listName: 'رومانيا' },
  RUS: { listName: 'الاتحاد الروسي' },
  RWA: { listName: 'رواندا' },
  SAU: { listName: 'العربية السعودية' },
  SDN: { listName: 'السودان' },
  SEN: { listName: 'السنغال' },
  SGP: { listName: 'سنغافورة' },
  SHN: { listName: 'سانت هيلينا' },
  SJM: { listName: 'جزيرتا سفالبارك وجان ماين' },
  SLB: { listName: 'جزر سليمان' },
  SLE: { listName: 'سيرا ليون' },
  SLV: { listName: 'السلفادور' },
  SMR: { listName: 'سان مارينو' },
  SOM: { listName: 'الصومال' },
  SPM: { listName: 'سانت بيير وميكيلون' },
  SRB: { listName: 'صربيا' },
  SSD: { listName: 'جنوب السودان' },
  STP: { listName: 'ساو تومي وبرينسيبي' },
  SUR: { listName: 'سورينام' },
  SVK: { listName: 'سلوفاكيا' },
  SVN: { listName: 'سلوفينيا' },
  SWE: { listName: 'السويد' },
  SWZ: { listName: 'اسواتيني' },
  SXM: { listName: 'سانت مارتن (الجانب الهولندي)' },
  SYC: { listName: 'جزر سيشل' },
  SYR: { listName: 'الجمهورية العربية السورية' },
  TCA: { listName: 'جزر تركس وكايكوس' },
  TCD: { listName: 'تشاد' },
  TGO: { listName: 'توجو' },
  THA: { listName: 'تايلند' },
  TJK: { listName: 'طاجيكستان' },
  TKL: { listName: 'توكيلاو' },
  TKM: { listName: 'تركمانستان' },
  TLS: { listName: 'تيمور - ليشتي' },
  TON: { listName: 'تونجا' },
  TTO: { listName: 'ترينيداد وتوباغو' },
  TUN: { listName: 'تونس' },
  TUR: { listName: 'تركيا' },
  TUV: { listName: 'توفالو' },
  TZA: { listName: 'جمهورية تنزانيا المتحدة' },
  UGA: { listName: 'أوغندا' },
  UKR: { listName: 'أوكرانيا' },
  URY: { listName: 'أوروغواي' },
  USA: { listName: 'الولايات المتحدة الأمريكية' },
  UZB: { listName: 'أوزبكستان' },
  VAT: { listName: 'الكرسي الرسولي' },
  VCT: { listName: 'سانت فينسنت وجزر غريناين' },
  VEN: { listName: 'جمهورية فنزويلا البوليفارية' },
  VGB: { listName: 'جزر فيرجن البريطانية' },
  VIR: { listName: 'جزر فيرجن الأمريكية' },
  VNM: { listName: 'فيت نام' },
  VUT: { listName: 'فانواتو' },
  WLF: { listName: 'جزر واليس وفوتونا' },
  WSM: { listName: 'ساموا' },
  X01: { listName: 'أطلانطس 01' },
  X02: { listName: 'أطلانطس 02' },
  X03: { listName: 'أطلانطس 03' },
  X04: { listName: 'أطلانطس 04' },
  X05: { listName: 'أطلانطس 05' },
  X06: { listName: 'أطلانطس 06' },
  X07: { listName: 'أطلانطس 07' },
  X08: { listName: 'أطلانطس 08' },
  X09: { listName: 'أطلانطس 09' },
  X10: { listName: 'أطلانطس 10' },
  X11: { listName: 'أطلانطس 11' },
  X12: { listName: 'أطلانطس 12' },
  X13: { listName: 'أطلانطس 13' },
  X14: { listName: 'أطلانطس 14' },
  X15: { listName: 'أطلانطس 15' },
  X16: { listName: 'أطلانطس 16' },
  X17: { listName: 'أطلانطس 17' },
  X18: { listName: 'أطلانطس 18' },
  X19: { listName: 'أطلانطس 19' },
  X20: { listName: 'أطلانطس 20' },
  YEM: { listName: 'اليمن' },
  ZAF: { listName: 'جنوب أفريقيا' },
  ZMB: { listName: 'زامبيا' },
  ZWE: { listName: 'زمبابوي' },
}
