module.exports = {
  classValueNotGreaterThan: `{{name}} 不应大于 {{value}}`,
  classValuesMustBeEqualTo: `{{name}} 的总和必须等于 {{value}}`,
  columnEmpty: `列 '$t({{columName}})' 必须有一个值`,
  countryReportYearGreaterThanCurrentYear: `数值应大于或等于{{minValue}}`,
  emptyField: `该字段为空`,
  forestAreaDoesNotMatchExtentOfForest: `与森林面积（1a）不匹配`,
  forestAreaExceedsExtentOfForest: `超过森林面积(1a)`,
  forestAreaReportedIsDifferentFromPreviousCycle: `报告的面积 ({{forestArea2025}}) 与先前报告的面积 ({{forestArea2020}}) 不同。请提供评论，解释为什么不同。`,
  forestSumAreaExceedsExtentOfForest: `报告的FRA类别总和超过了表格1a中报告的森林面积`,
  invalidLink: `无效链接`,
  landAreaExceedsTotalLandArea: `土地面积超过总土地面积 (1a) ({{value}})`,
  mustBeEqualToForestArea: `自然再生林和人工林的总和与总森林面积不相等`,
  mustBeEqualToForestExpansion: `子类别的总和应该等于森林扩张`,
  mustBeEqualToPlantedForest: `子类别的总和应该等于人工林`,
  mustBeEqualToPrimaryForest: `子类别的总和应该等于原生林`,
  mustBeEqualToPrivateOwnership: `子类别的总和应该等于私有所有权`,
  mustBeEqualToTotalGrowingStock: `总和应该等于总森林面积`,
  mustBeLessThanPrivateOwnership: `子类别的总和应小于私有所有权`,
  notEmpty: `值不能为空`,
  otherLandExceedsExtentOfForest: `超过其他土地面积(1a)`,
  remainingLandExceedsExtentOfForest: `超过其他土地（1a）`,
  shouldContainAtLeastOneCharacter: `值应至少包含一个字符`,
  subCategoryExceedsParent: `子类别超出母类别`,
  sumEqualTo: '{{categoryLabels}}（{{categoriesSum}}）之和必须等于{{maxValue}}',
  sumNotGreaterThan: `总和不应超过 {{maxValue}}`,
  sumSubCategoriesExceedParent: `{{categoryLabels}}的总和超过{{parentLabel}} ({{parentValue}})`,
  sumSubCategoriesNotEqualToParent: `{{categoryLabels}} ({{categoriesSum}})的和不等于{{parentLabel}} ({{parentValue}})`,
  valueCannotExceedMaximumValueReportedForForestArea: `值不能超过报告的森林面积 (1a) 的最大值 ({{maxForestArea}})`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `年份 {{year}} 的总和不能超过报告的森林面积 (1a) 的最大值 ({{maxForestArea}})`,
  valueCannotExceedMaximumValueReportedForLandArea: `值不能超过报告的土地面积 (1a) 的最大值 ({{maxLandArea}})`,
  valueEqualToSum: `总值应等于子类别的总和`,
  valueEqualToSumParent: `子类别 {{subcategories}} 的总和与 {{parentVariable}} {{parentCol}} {{parentTable}} <{{valueRounded}}> 不相等`,
  valueMustBePositive: `数值应大于零`,
  valueMustBeYear: `数值应为有效年份`,
  valueNotGreaterThan: `值不应大于 {{maxValue}}`,
  valuesAreInconsistent1aOr1b: `数值与表格1a或1b中报告的面积不一致`,
  valuesAreInconsistentWithNetChange: `数值与森林面积净变化不一致`,
}
