module.exports = {
  accepted: 'تم قبوله',
  add: 'إضافة',
  all: 'الكل',
  apply: 'نفذ',
  areYouSureYouWantToDelete: 'هل ترغب في حذف هذا الملف؟ عند حذف هذا الملف لا يمكن استرجاعة مره اخري.',
  calculate: 'احسب',
  cancel: 'إلغاء',
  clearTable: 'حذف بيانات الجدول',
  column: 'عمود',
  continue: 'اسْتَمَرَّ',
  copyToClipboard: 'انسخ للحافظة',
  countries: 'البلدان',
  country: 'البلد',
  custom: 'مخصص',
  dashboard: 'لوحة التحكم',
  dataExport: 'تصدير البيانات',
  delete: 'حذف',
  done: 'تم',
  email: 'البريد الإلكتروني',
  file: 'ملف',
  filterCountries: 'غربلة البلدان',
  fraPlatform: 'منصة تقييم الموارد الحرجية',
  globalFRA: 'تقييم الموارد الحرجية العالمية',
  hide: `إخفاء`,
  issues: 'مشاكل',
  label: 'التسمية',
  language: 'اللغة',
  lastEdit: 'آخر تعديل',
  link: 'الرابط',
  load: 'تحميل',
  loading: 'جاري التحميل...',
  login: 'المنطقة المحجوزة',
  name: 'الاسم',
  or: 'و',
  other: 'غير ذلك',
  otherSpecifyInComments: 'أخرى (حدد في التعليقات)',
  private: 'خاص',
  public: 'عام',
  regions: 'المناطق',
  resetAll: 'إعادة ضبط الكل',
  role: 'الدور',
  select: 'اختيار',
  selectAll: 'اختيار الكل',
  selectArea: 'اختيار منطقة جغرافية',
  selectFiles: 'اختر الملف',
  show: `إظهار`,
  showLess: 'أظهار الأقل',
  showMore: 'أظهار المزيد',
  source: 'المصدر',
  sources: 'المصادر',
  statisticalFactsheets: 'صحائف وقائع إحصائية',
  status: 'الحالة',
  submit: 'تقديم',
  submittedForApproval: 'تم التقديم للموافقة',
  submittedToReview: 'تم التقديم للمراجعة',
  tier: 'المستوى',
  tiers: { notSelected: '', high: 'مرتفع', medium: 'متوسط', low: 'منخفض' },
  totalPercentage: 'النسبة المئوية الإجمالية',
  trend: 'اتجاه',
  unknown: 'ولا واحدة/مجهولة',
  unselectAll: 'إلغاء اختيار الكل',
  updated: 'مُحَدَّث',
  userGuide: 'دليل المستخدم',
  variable: 'تقييم الموارد الحرجية متاح',
  view: 'مشاهدة',
  year: 'العام',
}
