module.exports = {
  classValueNotGreaterThan: `يجب ألا يتجاوز {{name}} قيمة {{value}}.`,
  classValuesMustBeEqualTo: `يجب أن يكون مجموع {{name}} مساويًا لـ {{value}}.`,
  columnEmpty: `يجب أن يحتوي العمود '$t({{columName}})' على قيمة.`,
  countryReportYearGreaterThanCurrentYear: `يجب أن تكون القيمة أكبر من أو تساوي {{minValue}}.`,
  emptyField: `هذة الخانة فارغة.`,
  forestAreaDoesNotMatchExtentOfForest: `لا يتطابق مع مساحة الغابات (1أ).`,
  forestAreaExceedsExtentOfForest: `تتجاوز مساحة الغابة (اأ)`,
  forestAreaReportedIsDifferentFromPreviousCycle: `المساحة المُبلَّغ عنها ({{forestArea2025}}) تختلف عن المُبلَّغ عنها سابقًا ({{forestArea2020}}). يرجى تقديم تعليق يشرح فيه سبب الاختلاف.`,
  forestSumAreaExceedsExtentOfForest: `مجموع فئات الفرا المُبلَّغ عنها تتجاوز مساحة الغابات المُبلَّغ عنها في الجدول 1أ.`,
  invalidLink: `رابط غير متوفر`,
  landAreaExceedsTotalLandArea: `مساحة الأرض تتجاوز مجموع مساحة الأراضي الكلية (1أ) ({{value}}).`,
  mustBeEqualToForestArea: `جموع الغابات المتجدد الطبيعيا والغابات المزروعة غير مساوٍ لإجمالي مساحة الغابات.`,
  mustBeEqualToForestExpansion: `يجب أن يكون مجموع الفئات الفرعية مساويًا لمساحة الغابات المتوسعة.`,
  mustBeEqualToPlantedForest: `يجب أن يكون مجموع الفئات الفرعية مساويًا لمساحة الغابات المزروعة.`,
  mustBeEqualToPrimaryForest: `يجب أن يكون مجموع الفئات الفرعية مساويًا لمساحة الغابة الأولية.`,
  mustBeEqualToPrivateOwnership: `يجب أن يكون مجموع الفئات الفرعية مساويًا لمجموع الملكية الخاصة.`,
  mustBeEqualToTotalGrowingStock: `يجب أن يكون مجموع القيم مساويًا لإجمالي مساحة الغابات.`,
  mustBeLessThanPrivateOwnership: `يجب أن يكون مجموع الفئات الفرعية أقل من ملكية القطاع الخاص.`,
  notEmpty: `لا يمكن أن تكون القيمة فارغة.`,
  otherLandExceedsExtentOfForest: `تتجاوز مساحة الأرض الأخرى (اأ)`,
  remainingLandExceedsExtentOfForest: `تجاوز مساحة الأراضي المتبقية (1أ).`,
  shouldContainAtLeastOneCharacter: `يجب أن تحتوي القيمة على حرف واحد على الأقل.`,
  subCategoryExceedsParent: `تتجاوز الفئة الفرعية الفئة الرئيسية.`,
  sumEqualTo: '{{maxValue}} مساويًا لـ ({{categoriesSum}}) {{categoryLabels}} يجب أن يكون مجموع ال',
  sumNotGreaterThan: `يجب ألا يتجاوز مجموع القيم {{maxValue}}.`,
  sumSubCategoriesExceedParent: `مجموع {{categoryLabels}} يتجاوز {{parentLabel}} ({{parentValue}}).`,
  sumSubCategoriesNotEqualToParent: `({{parentValue}}) {{parentLabel}}  ليس مساويًا لـ ({{categoriesSum}}) {{categoryLabels}}  يجب أن يكون مجموع ال`,
  valueCannotExceedMaximumValueReportedForForestArea: `لا يمكن أن تتجاوز هذة القيمة الحد الأقصى المبلغ عنه لقيمة مساحة الغابات (1أ) والذي يبلغ {{maxForestArea}}.`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `لا يمكن أن يتجاوز مجموع السنة {{year}} قيمة الحد الأقصى المبلغ عنها لمساحة الغابات (1أ) والتي تبلغ {{maxForestArea}}.`,
  valueCannotExceedMaximumValueReportedForLandArea: `هذة القيمة لا يمكن أن تتجاوز قيمة الحد الأقصى المبلغ عنه لمساحة الأرض (1أ) والتي تبلغ {{maxLandArea}}.`,
  valueEqualToSum: `يجب أن يكون القيمة الإجمالية مساوية لمجموع الفئات الفرعية.`,
  valueEqualToSumParent: `مجموع الفئات الفرعية {{subcategories}} لا يساوي {{parentVariable}} {{parentCol}} {{parentTable}} <{{valueRounded}}>.`,
  valueMustBePositive: `يجب أن يكون القيمة أكبر من الصفر.`,
  valueMustBeYear: `يجب أن تكون القيمة عامًا صحيحًا.`,
  valueNotGreaterThan: `يجب ألا تتجاوز القيمة {{maxValue}}.`,
  valuesAreInconsistent1aOr1b: `القيم غير متناسقة مع القيم المذكورة في الجداول 1أ أو 1ب.`,
  valuesAreInconsistentWithNetChange: `القيم غير متناسقة مع تغير صافي مساحة الغابات.`,
}
