module.exports = {
  login: 'Логин',

  password: 'Пароль',
  repeatPassword: 'Повторите пароль',
  email: 'Адрес электронной почты',
  cancel: 'Отменить',

  signInGoogle: 'Войти с Google',
  signInFRA: 'Войти с ОЛР',

  changePassword: 'Изменить пароль',
  expired: 'Похоже, ссылка, на которую вы щелкнули, просрочена или недействительна',

  forgotPasswordTitle: 'Введите свою электронную почту и отправьте форму для получения инструкций по электронной почте',
  forgotPassword: 'Забыли пароль?',

  notAuthorized: 'Пользователь не авторизован',
  errorOccurred: 'Произошла ошибка',
  noInvitation: 'Приглашение не найдено',
  noMatchPasswords: 'Пароли не совпадают',
  noEmptyPassword: 'Пароль не может быть пустым',
  noMatchingUser:
    'Мы не смогли найти ни одного пользователя, соответствующего этим учетным данным. Убедитесь, что у вас есть действующая учетная запись ОЛР.',
  noMatchingEmail:
    'Мы не смогли найти ни одного пользователя, соответствующего этому адресу электронной почты. Убедитесь, что у вас есть действующая учетная запись ОЛР.',
  passwordError:
    'Пароль должен состоять из шести или более символов и содержать как минимум один нижний регистр, один буквенный символ верхнего регистра и одну цифру.',
  invalidEmail: 'Электронная почта недействительна',
  emptyEmail: 'Электронная почта не может быть пустой',
  noLongerValid: 'Похоже, ваш запрос больше не действителен.',

  passwordResetSent:
    'Запрос на сброс пароля был успешно отправлен. Вскоре вы получите электронное письмо с инструкциями',
  passwordChanged: 'Пароль был изменен',
  missingCredentials: 'Отсутствующие учетные данные',

  accessLimited:
    'Доступ к зарезервированной области ограничивается экспертами, участвующими в процессе отчетности ОЛР.',
  returnHome: 'Чтобы вернуться на главную страницу, нажмите ',
  returnHomeClick: 'здесь.',

  resetPassword: 'Сброс пароля',
  alreadyLinked: 'Выбранная учетная запись Google уже связана с другим пользователем',
  noShortPassword: 'Пароль слишком короткий',
  noMatchingLocalUser: 'Мы не смогли найти пользователя, соответствующего этим учетным данным.',
  noMatchingProvider: 'Мы не смогли найти пользователя, соответствующего этим учетным данным.',
  noActiveAccount: 'Учетная запись не активна. Убедитесь, что вы приняли приглашение',
  googleOnlyAccount: 'Учетная запись связана с аутентификацией Google',
  alreadyAcceptedInvitation: 'Это приглашение уже принято',
  invitationMessage: 'Вас пригласили {{assessment}} {{cycle}} как {{role}} для {{country}}',
  invitationProvidersRegistered: 'У вас уже есть учетная запись, зарегистрированная в {{authProviderNames}}',
  acceptInvitation: 'Принять приглашение',
  acceptInvitationWithFra: 'Принять приглашение с ОЛР',
  acceptInvitationWithGoogle: 'Принять приглашение с Google',
  invitationExpired: 'Это приглашение истекло',
  logoutSuccessful: 'Вы успешно вышли из системы',
}
