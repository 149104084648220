module.exports = {
  classValueNotGreaterThan: `{{name}} no debe ser mayor que {{value}}`,
  classValuesMustBeEqualTo: `{{name}} suma debe ser igual a {{value}}`,
  columnEmpty: `La columna "$t({{columName}})" debe tener un valor`,
  countryReportYearGreaterThanCurrentYear: `El valor debe ser mayor o igual que {{minValue}}`,
  emptyField: `Este campo está vacío`,
  forestAreaDoesNotMatchExtentOfForest: `No coincide con el valor de Extensión de bosque (1a)`,
  forestAreaExceedsExtentOfForest: `Superior al área de bosque (1a)`,
  forestAreaReportedIsDifferentFromPreviousCycle: `El valor de área introducido ({{forestArea2025}}) difiere de la introducida anteriormente ({{forestArea2020}}). Por favor explique las razones de esa diferencia `,
  forestSumAreaExceedsExtentOfForest: `La suma de las categorías de FRA reportadas supera el valor de área total de bosque introducida en la tabla 1a`,
  invalidLink: `Enlace inválido`,
  landAreaExceedsTotalLandArea: `La superficie terrestre supera la superficie terrestre total (1a) ({{value}})`,
  mustBeEqualToForestArea: `La suma de los valores de área de Bosque con procesos de regenración natural y Bosque plantado no es igual al valor de área total de Bosque`,
  mustBeEqualToForestExpansion: `El total de subcategorías debe ser igual al valor de Expansión forestal`,
  mustBeEqualToPlantedForest: `El total de las subcategorías debe ser igual al valor de área de Bosque Plantado`,
  mustBeEqualToPrimaryForest: `La suma de las subcategorías debe ser igual al valor de área de Bosque Primario`,
  mustBeEqualToPrivateOwnership: `La suma de las subcategorías debe ser igual al valor de área de bosque de Propiedad privada`,
  mustBeEqualToTotalGrowingStock: `El valor sumado debe ser igual a Extensión Total de Bosque`,
  mustBeLessThanPrivateOwnership: `La suma de las subcategorías debe ser inferior al valor de área de bosque de Propiedad privada`,
  notEmpty: `El valor no puede estar vacío`,
  otherLandExceedsExtentOfForest: `Superior al área de otras tierras (1a)`,
  remainingLandExceedsExtentOfForest: `Supera el valor de Superficie Terrestre Restante (1a)`,
  shouldContainAtLeastOneCharacter: `El valor debe contener al menos un carácter`,
  subCategoryExceedsParent: `El valor de la subcategoría supera el valor total de la categoria`,
  sumEqualTo: 'La suma de {{categoryLabels}} ({{categoriesSum}}) debe ser igual a {{maxValue}}',
  sumNotGreaterThan: `La suma no debe superar {{maxValue}}`,
  sumSubCategoriesExceedParent: `La suma de {{categoryLabels}} supera {{parentLabel}} ({{parentValue}})`,
  sumSubCategoriesNotEqualToParent: `La suma de {{categoryLabels}} ({{categoriesSum}}) no es igual a {{parentLabel}} ({{parentValue}})`,
  valueCannotExceedMaximumValueReportedForForestArea: `El valor no puede superar el valor máximo ({{maxÁreaForestal}}) introducido para área de bosque (1a)`,
  valueCannotExceedMaximumValueReportedForForestAreaYear: `La suma del año {{year}} no puede superar el valor máximo ({{maxForestArea}}) introducido para área de bosque (1a)`,
  valueCannotExceedMaximumValueReportedForLandArea: `El valor no puede superar el valor máximo ({{maxLandArea}}) introducido para la superficie terrestre (1a)`,
  valueEqualToSum: `El valor total debe ser igual a la suma de las subcategorías`,
  valueEqualToSumParent: `La suma de subcategorías {{subcategories}} no es igual a {{parentVariable}} {{parentCol}} {{parentTable}} <{{valorRedondeado}}>`,
  valueMustBePositive: `El valor debe ser mayor que cero`,
  valueMustBeYear: `El valor debe ser un año válido`,
  valueNotGreaterThan: `El valor no debe ser superior a {{maxValue}}.`,
  valuesAreInconsistent1aOr1b: `Los valores no coinciden con las superficies indicadas en las tablas 1a o 1b.`,
  valuesAreInconsistentWithNetChange: `Los valores no coinciden con los de Cambio Neto en el área de Bosque`,
}
