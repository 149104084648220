module.exports = {
  WO: { listName: 'Global' },
  AF: { listName: 'Afrique' },
  AN: { listName: 'Antarctica' },
  AS: { listName: 'Asie' },
  AT: { listName: 'Atlantis' },
  EU: { listName: 'Europe' },
  NA: { listName: 'Amérique du Nord et Centrale' },
  OC: { listName: 'Océanie' },
  SA: { listName: 'Amérique du sud' },

  ASEAN: { listName: 'ASEAN' },
  FE: { listName: 'FOREST EUROPE' },
  EU27: { listName: 'EU-27' },
  ECOMIFAC: { listName: 'Espace COMIFAC' },
  MP: { listName: 'Montreal Process' },
  UNECE: { listName: 'UNECE' },
  ITTO: { listName: 'International Tropical Timber Organization' },

  ABW: { listName: 'Aruba' },
  AFG: { listName: 'Afghanistan' },
  AGO: { listName: 'Angola' },
  AIA: { listName: 'Anguilla' },
  ALB: { listName: 'Albanie' },
  AND: { listName: 'Andorre' },
  ARE: { listName: 'Émirats arabes unis' },
  ARG: { listName: 'Argentine' },
  ARM: { listName: 'Arménie' },
  ASM: { listName: 'Samoa américaines' },
  ATG: { listName: 'Antigua-et-Barbuda' },
  AUS: { listName: 'Australie' },
  AUT: { listName: 'Autriche' },
  AZE: { listName: 'Azerbaïdjan' },
  BDI: { listName: 'Burundi' },
  BEL: { listName: 'Belgique' },
  BEN: { listName: 'Bénin' },
  BES: { listName: 'Bonaire, Sint Eustatius et Saba' },
  BFA: { listName: 'Burkina Faso' },
  BGD: { listName: 'Bangladesh' },
  BGR: { listName: 'Bulgarie' },
  BHR: { listName: 'Bahreïn' },
  BHS: { listName: 'Bahamas' },
  BIH: { listName: 'Bosnie-Herzégovine' },
  BLM: { listName: 'Saint-Barthélemy' },
  BLR: { listName: 'Bélarus' },
  BLZ: { listName: 'Belize' },
  BMU: { listName: 'Bermudes' },
  BOL: { listName: 'Bolivie (État plurinational de)' },
  BRA: { listName: 'Brésil' },
  BRB: { listName: 'Barbade' },
  BRN: { listName: 'Brunéi Darussalam' },
  BTN: { listName: 'Bhoutan' },
  BWA: { listName: 'Botswana' },
  CAF: { listName: 'République centrafricaine' },
  CAN: { listName: 'Canada' },
  CHE: { listName: 'Suisse' },
  CHL: { listName: 'Chili' },
  CHN: { listName: 'Chine' },
  CIV: { listName: "Côte d'Ivoire" },
  CMR: { listName: 'Cameroun' },
  COD: { listName: 'République démocratique du Congo' },
  COG: { listName: 'Congo' },
  COK: { listName: 'Îles Cook' },
  COL: { listName: 'Colombie' },
  COM: { listName: 'Comores' },
  CPV: { listName: 'Cabo Verde' },
  CRI: { listName: 'Costa Rica' },
  CUB: { listName: 'Cuba' },
  CUW: { listName: 'Curaçao' },
  CYM: { listName: 'Îles Caïmanes' },
  CYP: { listName: 'Chypre' },
  CZE: { listName: 'Tchéquie (la)' },
  DEU: { listName: 'Allemagne' },
  DJI: { listName: 'Djibouti' },
  DMA: { listName: 'Dominique' },
  DNK: { listName: 'Danemark' },
  DOM: { listName: 'République dominicaine' },
  DZA: { listName: 'Algérie' },
  ECU: { listName: 'Équateur' },
  EGY: { listName: 'Égypte' },
  ERI: { listName: 'Érythrée' },
  ESH: { listName: 'Sahara occidental' },
  ESP: { listName: 'Espagne' },
  EST: { listName: 'Estonie' },
  ETH: { listName: 'Éthiopie' },
  FIN: { listName: 'Finlande' },
  FJI: { listName: 'Fidji' },
  FLK: { listName: 'Îles Falkland (Malvinas)' },
  FRA: { listName: 'France' },
  FRO: { listName: 'Îles Féroé' },
  FSM: { listName: 'Micronésie (États fédérés de)' },
  GAB: { listName: 'Gabon' },
  GBR: { listName: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord" },
  GEO: { listName: 'Géorgie' },
  GGY: { listName: 'Guernesey' },
  GHA: { listName: 'Ghana' },
  GIB: { listName: 'Gibraltar' },
  GIN: { listName: 'Guinée' },
  GLP: { listName: 'Guadeloupe' },
  GMB: { listName: 'Gambie' },
  GNB: { listName: 'Guinée-Bissau' },
  GNQ: { listName: 'Guinée équatoriale' },
  GRC: { listName: 'Grèce' },
  GRD: { listName: 'Grenade' },
  GRL: { listName: 'Groenland' },
  GTM: { listName: 'Guatemala' },
  GUF: { listName: 'Guyane française' },
  GUM: { listName: 'Guam' },
  GUY: { listName: 'Guyana' },
  HND: { listName: 'Honduras' },
  HRV: { listName: 'Croatie' },
  HTI: { listName: 'Haïti' },
  HUN: { listName: 'Hongrie' },
  IDN: { listName: 'Indonésie' },
  IMN: { listName: 'Île de Man' },
  IND: { listName: 'Inde' },
  IRL: { listName: 'Irlande' },
  IRN: { listName: "Iran (République islamique d')" },
  IRQ: { listName: 'Iraq' },
  ISL: { listName: 'Islande' },
  ISR: { listName: 'Israël' },
  ITA: { listName: 'Italie' },
  JAM: { listName: 'Jamaïque' },
  JEY: { listName: 'Jersey' },
  JOR: { listName: 'Jordanie' },
  JPN: { listName: 'Japon' },
  KAZ: { listName: 'Kazakhstan' },
  KEN: { listName: 'Kenya' },
  KGZ: { listName: 'Kirghizistan' },
  KHM: { listName: 'Cambodge' },
  KIR: { listName: 'Kiribati' },
  KNA: { listName: 'Saint-Kitts-et-Nevis' },
  KOR: { listName: 'République de Corée' },
  KWT: { listName: 'Koweït' },
  LAO: { listName: 'République démocratique populaire lao' },
  LBN: { listName: 'Liban' },
  LBR: { listName: 'Libéria' },
  LBY: { listName: 'Libye' },
  LCA: { listName: 'Sainte-Lucie' },
  LIE: { listName: 'Liechtenstein' },
  LKA: { listName: 'Sri Lanka' },
  LSO: { listName: 'Lesotho' },
  LTU: { listName: 'Lituanie' },
  LUX: { listName: 'Luxembourg' },
  LVA: { listName: 'Lettonie' },
  MAF: { listName: 'Saint-Martin (partie française)' },
  MAR: { listName: 'Maroc' },
  MCO: { listName: 'Monaco' },
  MDA: { listName: 'République de Moldova' },
  MDG: { listName: 'Madagascar' },
  MDV: { listName: 'Maldives' },
  MEX: { listName: 'Mexique' },
  MHL: { listName: 'Îles Marshall' },
  MKD: { listName: 'République de Macédonie du Nord' },
  MLI: { listName: 'Mali' },
  MLT: { listName: 'Malte' },
  MMR: { listName: 'Myanmar' },
  MNE: { listName: 'Monténégro' },
  MNG: { listName: 'Mongolie' },
  MNP: { listName: 'Îles Mariannes du Nord' },
  MOZ: { listName: 'Mozambique' },
  MRT: { listName: 'Mauritanie' },
  MSR: { listName: 'Montserrat' },
  MTQ: { listName: 'Martinique' },
  MUS: { listName: 'Maurice' },
  MWI: { listName: 'Malawi' },
  MYS: { listName: 'Malaisie' },
  MYT: { listName: 'Mayotte' },
  NAM: { listName: 'Namibie' },
  NCL: { listName: 'Nouvelle-Calédonie' },
  NER: { listName: 'Niger' },
  NFK: { listName: 'Île Norfolk' },
  NGA: { listName: 'Nigéria' },
  NIC: { listName: 'Nicaragua' },
  NIU: { listName: 'Nioué' },
  NLD: { listName: 'Pays-Bas (Royaume des)' },
  NOR: { listName: 'Norvège' },
  NPL: { listName: 'Népal' },
  NRU: { listName: 'Nauru' },
  NZL: { listName: 'Nouvelle-Zélande' },
  OMN: { listName: 'Oman' },
  PAK: { listName: 'Pakistan' },
  PAN: { listName: 'Panama' },
  PCN: { listName: 'Pitcairn' },
  PER: { listName: 'Pérou' },
  PHL: { listName: 'Philippines' },
  PLW: { listName: 'Palaos' },
  PNG: { listName: 'Papouasie-Nouvelle-Guinée' },
  POL: { listName: 'Pologne' },
  PRI: { listName: 'Porto Rico' },
  PRK: { listName: 'République populaire démocratique de Corée' },
  PRT: { listName: 'Portugal' },
  PRY: { listName: 'Paraguay' },
  PSE: { listName: 'Palestine' },
  PYF: { listName: 'Polynésie française' },
  QAT: { listName: 'Qatar' },
  REU: { listName: 'Réunion' },
  ROU: { listName: 'Roumanie' },
  RUS: { listName: 'Fédération de Russie' },
  RWA: { listName: 'Rwanda' },
  SAU: { listName: 'Arabie saoudite' },
  SDN: { listName: 'Soudan' },
  SEN: { listName: 'Sénégal' },
  SGP: { listName: 'Singapour' },
  SHN: { listName: 'Sainte-Hélène' },
  SJM: { listName: 'Îles Svalbard et Jan Mayen' },
  SLB: { listName: 'Îles Salomon' },
  SLE: { listName: 'Sierra Leone' },
  SLV: { listName: 'El Salvador' },
  SMR: { listName: 'Saint-Marin' },
  SOM: { listName: 'Somalie' },
  SPM: { listName: 'Saint-Pierre-et-Miquelon' },
  SRB: { listName: 'Serbie' },
  SSD: { listName: 'Soudan du Sud' },
  STP: { listName: 'Sao Tomé-et-Principe' },
  SUR: { listName: 'Suriname' },
  SVK: { listName: 'Slovaquie' },
  SVN: { listName: 'Slovénie' },
  SWE: { listName: 'Suède' },
  SWZ: { listName: 'Eswatini' },
  SXM: { listName: 'Sint Maarten (Dutch part)' },
  SYC: { listName: 'Seychelles' },
  SYR: { listName: 'République arabe syrienne' },
  TCA: { listName: 'Îles Turques et Caïques' },
  TCD: { listName: 'Tchad' },
  TGO: { listName: 'Togo' },
  THA: { listName: 'Thaïlande' },
  TJK: { listName: 'Tadjikistan' },
  TKL: { listName: 'Tokélaou' },
  TKM: { listName: 'Turkménistan' },
  TLS: { listName: 'Timor-Leste' },
  TON: { listName: 'Tonga' },
  TTO: { listName: 'Trinité-et-Tobago' },
  TUN: { listName: 'Tunisie' },
  TUR: { listName: 'Türkiye' },
  TUV: { listName: 'Tuvalu' },
  TZA: { listName: 'République-Unie de Tanzanie' },
  UGA: { listName: 'Ouganda' },
  UKR: { listName: 'Ukraine' },
  URY: { listName: 'Uruguay' },
  USA: { listName: "États-Unis d'Amérique" },
  UZB: { listName: 'Ouzbékistan' },
  VAT: { listName: 'Saint-Siège' },
  VCT: { listName: 'Saint-Vincent-et-les Grenadines' },
  VEN: { listName: 'Venezuela (République bolivarienne du)' },
  VGB: { listName: 'Îles Vierges britanniques' },
  VIR: { listName: 'Îles Vierges américaines' },
  VNM: { listName: 'Viet Nam' },
  VUT: { listName: 'Vanuatu' },
  WLF: { listName: 'Îles Wallis-et-Futuna' },
  WSM: { listName: 'Samoa' },
  X01: { listName: 'Atlantis 01' },
  X02: { listName: 'Atlantis 02' },
  X03: { listName: 'Atlantis 03' },
  X04: { listName: 'Atlantis 04' },
  X05: { listName: 'Atlantis 05' },
  X06: { listName: 'Atlantis 06' },
  X07: { listName: 'Atlantis 07' },
  X08: { listName: 'Atlantis 08' },
  X09: { listName: 'Atlantis 09' },
  X10: { listName: 'Atlantis 10' },
  X11: { listName: 'Atlantis 11' },
  X12: { listName: 'Atlantis 12' },
  X13: { listName: 'Atlantis 13' },
  X14: { listName: 'Atlantis 14' },
  X15: { listName: 'Atlantis 15' },
  X16: { listName: 'Atlantis 16' },
  X17: { listName: 'Atlantis 17' },
  X18: { listName: 'Atlantis 18' },
  X19: { listName: 'Atlantis 19' },
  X20: { listName: 'Atlantis 20' },
  YEM: { listName: 'Yémen' },
  ZAF: { listName: 'Afrique du Sud' },
  ZMB: { listName: 'Zambie' },
  ZWE: { listName: 'Zimbabwe' },
}
