module.exports = {
  description: {
    dataSource: {
      referenceToTataSource: '$t(dataSource.referenceToTataSource)',
      typeOfDataSource: '$t(dataSource.typeOfDataSource)',
      variable: '$t(dataSource.fraVariable)',
      yearForDataSource: '$t(dataSource.yearForDataSource)',
      comments: '$t(dataSource.comments)',
    },
  },
}
